import dayjs from 'dayjs';
import { Form, ResponsiveContext } from 'grommet';
import { List } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ApiError,
  ClientService,
  EquipmentCategoryService,
  FacilityService,
  FSERegistrationStatusService,
} from '/src/api';
import {
  Box,
  BreadcrumbNav,
  BreadcrumbNavProps,
  FirstActivePeriodSelect,
  FormCard,
  FormCardSection,
  FormPage,
  Input,
  Line,
  Select,
} from '/src/components';
import { config } from '/src/config';
import { useEquipmentStore, useGlobalStore, useUserStore } from '/src/context';
import { EquipmentCategory, Facility, FSERegistrationStatus, Region, TFacilityId, TProgramId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { DateString, SelectOptions, TResponseMetadata } from '/src/lib/types';
import {
  facilityIsOrWa,
  getFacilityLabel,
  getFormattedDateString,
  getIsCanada,
  getIsMobile,
  getPageTitle,
  getQueryParams,
} from '/src/utils';

export const CreateEquipmentPage = observer(() => {
  /** Context **/
  const navigate = useNavigate();
  const globalStore = useGlobalStore();
  const equipmentStore = useEquipmentStore();
  const userStore = useUserStore();
  const screenSize = useContext(ResponsiveContext);
  const isMobile = getIsMobile(screenSize);
  const location = useLocation();

  /** Params **/
  const queryParams = getQueryParams(location.search);
  const categoryName = queryParams.category?.replace(/%20/g, ' ') ?? '';
  const facilityId = parseInt(queryParams.facility_id ?? '');
  const params = useParams();
  const clientId = parseInt(params.client_id ?? '');

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFacilities, setIsLoadingFacilities] = useState(false);
  const [, setIsLoadingCategories] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<Facility>();
  const [currentFacilityPage, setCurrentFacilityPage] = useState(1);
  const [isUserLocation, setIsUserLocation] = useState(true);

  const [categories, setCategories] = useState<EquipmentCategory[]>();
  const [fseRegistrationStatuses, setFSERegistrationStatuses] = useState<FSERegistrationStatus[]>();
  const [clientName, setClientName] = useState('');
  const [clientRegion, setClientRegion] = useState<Region>();

  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [facilityOptions, setFacilityOptions] = useState<SelectOptions<TFacilityId>>([]);
  const [facilityMetadata, setFacilityMetadata] = useState<TResponseMetadata>();
  const [breadcrumbNavItems, setBreadcrumbNavItems] = useState<BreadcrumbNavProps['previousPages']>();

  /** Computed **/
  const programId = selectedFacility?.program?.id;
  const isCanada = getIsCanada(clientRegion);
  const isOrWa = facilityIsOrWa(selectedFacility);
  const isForklift = !!(equipmentStore.formValues.category_name ?? '').toLowerCase().includes('forklift');

  const firstDayInServiceRange = useMemo(() => {
    if (!selectedFacility || !equipmentStore.formValues.first_active_reporting_quarter) return;
    const start = dayjs(selectedFacility.first_active_reporting_quarter).toISOString();
    const end = dayjs(equipmentStore.formValues.first_active_reporting_quarter)
      .add(2, 'months')
      .endOf('month')
      .toISOString();

    return [start, end] as [start: DateString, end: DateString];
  }, [selectedFacility, equipmentStore.formValues.first_active_reporting_quarter]);

  const equipmentTypeOptions =
    categories
      ?.find(({ name }) => name === equipmentStore.formValues.category_name)
      ?.equipment_types?.map((type) => ({
        label: type.name,
        value: type.id,
      })) ?? [];

  /** Methods **/
  const fetchPageData = async () => {
    try {
      const [{ data: facilitiesData, meta: facilitiesMeta }, registrationStatuses] = await Promise.all([
        FacilityService.list({ client_id: clientId, page: currentFacilityPage, limit: 1000 }),
        FSERegistrationStatusService.listFSERegistrationStatuses(),
      ]);

      const client =
        userStore.isClientUser || userStore.isFacilityUser
          ? userStore.user?.clients.find((client) => client.id === clientId)
          : await ClientService.get({ id: clientId });

      if (client) {
        setClientName(client.name);
        setClientRegion(client.hq_address_region);
      }

      setFacilities(facilitiesData);
      setFacilityMetadata(facilitiesMeta);
      setFacilityOptions([
        ...facilityOptions,
        ...facilitiesData.map((facility) => ({ label: getFacilityLabel(facility, true), value: facility.id })),
      ]);
      const facility = facilityId ? facilitiesData.find((facility) => facility.id === facilityId) : selectedFacility;
      setSelectedFacility(facility);
      fetchNavItems(client?.name ?? '', facility);
      const programId = facility?.program?.id;
      setFSERegistrationStatuses(registrationStatuses);

      let category: EquipmentCategory | undefined;
      if (programId) {
        const categories = await EquipmentCategoryService.listEquipmentCategories({ program_id: programId });
        setCategories(categories);
        category = categories?.find((category) => category.name === categoryName);
      }

      equipmentStore.setFormValues({
        ...equipmentStore.formValues,
        facility_id: facilityId,
        category_name: categoryName,
        equipment_category_id: category?.id ?? NaN,
        is_metered: isCanada ? true : equipmentStore.formValues.is_metered,
      });
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.generic.error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async (programId: TProgramId) => {
    setIsLoadingCategories(true);
    try {
      const categories = await EquipmentCategoryService.listEquipmentCategories({ program_id: programId });
      setCategories(categories);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.generic.error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  const fetchNavItems = (clientName: string, facility?: Facility) => {
    const navItems: BreadcrumbNavProps['previousPages'] = [
      { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
      { name: clientName, link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
    ];
    if (facility) {
      navItems.push({ name: 'Facilities', link: `/clients/${clientId}/facilities` });
      navItems.push({
        name: getFacilityLabel(facility),
        link: `/clients/${clientId}/facilities/${facility.id}`,
      });
      navItems.push({ name: 'Equipment', link: `/clients/${clientId}/facilities/${facility.id}/equipment` });
    } else {
      navItems.push({ name: 'Equipment' });
    }
    setBreadcrumbNavItems(navItems);
  };

  const nextFacilityPage = async () => {
    if (isLoadingFacilities || (facilityMetadata && currentFacilityPage + 1 > facilityMetadata.last_page)) return;
    setIsLoadingFacilities(true);
    try {
      setCurrentFacilityPage(currentFacilityPage + 1);
      const { meta, data } = await FacilityService.list({
        client_id: clientId,
        page: currentFacilityPage + 1,
      });
      setFacilities([...facilities, ...data]);
      setFacilityOptions([
        ...facilityOptions,
        ...data.map((facility) => ({ label: getFacilityLabel(facility), value: facility.id })),
      ]);
      setFacilityMetadata(meta);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchEquipment.error);
    } finally {
      setIsLoadingFacilities(false);
    }
  };

  const createEquipment = async () => {
    if (!selectedFacility) return;

    try {
      setIsCreating(true);

      const equipment = await (userStore.isInternalUser
        ? equipmentStore.createEquipmentInternal(selectedFacility)
        : equipmentStore.createEquipmentExternal(selectedFacility));

      if (equipment) {
        toast.success(toastMessages.createEquipment.success);
        navigate(`/clients/${clientId}/equipment/${equipment.id}`);
      }
    } catch (err) {
      const error = err as ApiError;
      let showToast = true;
      if (error.status === 400) {
        const body = (error.body ?? {}) as Record<string, string>;
        if (body?.message?.toLowerCase().includes('first active reporting quarter')) {
          equipmentStore.formErrors['first_active_reporting_quarter'] = body?.message;
          showToast = false;
        }
      }
      if (showToast) globalStore.handleApiError(error, toastMessages.createEquipment.error);
    } finally {
      setIsCreating(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Add Equipment');
    equipmentStore.resetForm();
  }, []);

  useEffect(() => {
    if (isLoading) {
      fetchPageData();
    }
  }, [isLoading]);

  useEffect(() => {
    if (programId && equipmentStore.formValues.category_name) fetchCategories(programId);
  }, [programId, equipmentStore.formValues.category_name]);

  // Auto-fill lat/long (if applicable)
  useEffect(() => {
    const latLongIsEmpty = !equipmentStore.formValues.latitude && !equipmentStore.formValues.longitude;
    if (
      isForklift &&
      (latLongIsEmpty || !isUserLocation) &&
      selectedFacility?.address_latitude &&
      selectedFacility?.address_longitude
    ) {
      equipmentStore.updateFormValue('latitude', selectedFacility.address_latitude);
      equipmentStore.updateFormValue('longitude', selectedFacility.address_longitude);
      setIsUserLocation(false);
    }
  }, [
    isForklift,
    equipmentStore.formValues.latitude,
    equipmentStore.formValues.longitude,
    isUserLocation,
    selectedFacility?.address_latitude,
    selectedFacility?.address_longitude,
  ]);

  // Change Category
  useEffect(() => {
    if (categories) {
      const category = categories?.find(({ name }) => name === equipmentStore.formValues.category_name);

      const options =
        category?.equipment_types?.map((type) => ({
          label: type.name,
          value: type.id,
        })) ?? [];

      equipmentStore.updateFormValue('equipment_category_id', category?.id);
      equipmentStore.updateFormValue('equipment_type_id', options?.length === 1 ? options[0].value : NaN);
    }
  }, [categories, equipmentStore.formValues?.category_name]);

  // Change Facility
  useEffect(() => {
    if (equipmentStore.formValues.facility_id && selectedFacility?.id !== equipmentStore.formValues.facility_id) {
      const facility = facilities.find((facility) => facility.id === equipmentStore.formValues.facility_id);
      setSelectedFacility(facility);
      fetchNavItems(clientName, facility);
    }
  }, [equipmentStore.formValues.facility_id, selectedFacility, facilities, clientName]);

  /** Render **/
  return (
    <FormPage
      title={`Add ${equipmentStore.formValues.category_name || 'Equipment'}`}
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={breadcrumbNavItems ?? []}
          currentPageName={`Add ${equipmentStore.formValues.category_name || 'Equipment'}`}
        />
      }
      isLoading={isLoading}
    >
      <FormCard
        title="Equipment Details"
        icon={<List size="24px" color="brand" />}
        isLoading={isLoading || isCreating}
        onSubmit={createEquipment}
      >
        <Form>
          <Box direction={isMobile ? 'column' : 'row'} margin={{ bottom: '1rem' }}>
            <Box width="60%" gap="1rem">
              <FormCardSection row>
                <Select
                  id="category_name"
                  label="Equipment Category"
                  placeholder="Choose..."
                  value={equipmentStore.formValues.category_name}
                  setValue={(value) => equipmentStore.updateFormValue('category_name', value)}
                  options={categories?.map((category) => category.name) ?? []}
                  error={equipmentStore.formErrors['category_name']}
                  emptySearchMessage="No Facility selected."
                  required
                  width="50%"
                />
                <Select
                  id="equipment_type_id"
                  label="Equipment Type"
                  placeholder="Choose..."
                  emptySearchMessage="No Equipment Category selected."
                  value={equipmentStore.formValues.equipment_type_id}
                  setValue={(value) => equipmentStore.updateFormValue('equipment_type_id', value)}
                  options={equipmentTypeOptions}
                  width="50%"
                  error={equipmentStore.formErrors['equipment_type_id']}
                  required
                />
              </FormCardSection>
              <Line margin="0.5rem" />
              <FormCardSection>
                <Box row gap="1rem">
                  <Input
                    id="serial_number"
                    label="Serial Number"
                    value={equipmentStore.formValues.serial_number}
                    setValue={(value) => equipmentStore.updateFormValue('serial_number', value)}
                    error={equipmentStore.formErrors['serial_number']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    required
                  />
                  <Input
                    id="unit_number"
                    label="Unit Number"
                    value={equipmentStore.formValues.unit_number ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('unit_number', value)}
                    error={equipmentStore.formErrors['unit_number']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                  />
                </Box>
                <Box row gap="1rem">
                  <Input
                    id="model_year"
                    label="Model Year"
                    value={equipmentStore.formValues.model_year}
                    setValue={(value) => equipmentStore.updateFormValue('model_year', value)}
                    error={equipmentStore.formErrors['model_year']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    type="number"
                    required
                  />
                  <Input
                    id="manufacturer"
                    label="Manufacturer"
                    value={equipmentStore.formValues.manufacturer}
                    setValue={(value) => equipmentStore.updateFormValue('manufacturer', value)}
                    error={equipmentStore.formErrors['manufacturer']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    required
                  />
                  <Input
                    id="model_number"
                    label="Model Number"
                    value={equipmentStore.formValues.model_number}
                    setValue={(value) => equipmentStore.updateFormValue('model_number', value)}
                    error={equipmentStore.formErrors['model_number']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                  />
                </Box>
              </FormCardSection>
              <Line margin="xsmall" />
              <FormCardSection>
                <Box row gap="1rem">
                  <Select
                    id="is_metered"
                    label="Metered"
                    value={equipmentStore.formValues.is_metered ? 'Yes' : 'No'}
                    setValue={(value) => equipmentStore.updateFormValue('is_metered', value === 'Yes')}
                    options={['Yes', 'No']}
                    error={equipmentStore.formErrors['is_metered']}
                    required
                    disabled={isCanada}
                    fill="horizontal"
                  />
                  <FirstActivePeriodSelect
                    id="first_active_reporting_quarter"
                    label="First Reporting Period"
                    placeholder="Select Period..."
                    value={equipmentStore.formValues.first_active_reporting_quarter ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('first_active_reporting_quarter', value)}
                    clientId={selectedFacility?.client_id}
                    reportingPeriodTypeId={selectedFacility?.reporting_period_type_id}
                    firstActivePeriod={selectedFacility?.first_active_reporting_quarter}
                    error={equipmentStore.formErrors['first_active_reporting_quarter']}
                    required
                    fill="horizontal"
                  />
                  {isOrWa && (
                    <Input
                      animation={{ type: 'slideRight' }}
                      id="first_day_in_service"
                      label="First Day in Service"
                      fill="horizontal"
                      value={equipmentStore.formValues.first_day_in_service ?? ''}
                      displayValue={getFormattedDateString(
                        equipmentStore.formValues.first_day_in_service ?? '',
                        isCanada
                      )}
                      dateBounds={firstDayInServiceRange}
                      setValue={(value) => equipmentStore.updateFormValue('first_day_in_service', value)}
                      error={equipmentStore.formErrors['first_day_in_service']}
                      onSubmit={createEquipment}
                      componentType="date"
                      required
                      disabled={!equipmentStore.formValues.first_active_reporting_quarter}
                      placeholder={
                        !equipmentStore.formValues.first_active_reporting_quarter
                          ? 'Select a First Reporting Period'
                          : undefined
                      }
                    />
                  )}
                </Box>
              </FormCardSection>
              <Line margin="xsmall" />
              <Box flex="grow">
                <Input
                  id="comments"
                  label="Comments"
                  value={equipmentStore.formValues.comments ?? ''}
                  setValue={(value) => equipmentStore.updateFormValue('comments', value)}
                  error={equipmentStore.formErrors['comments']}
                  onSubmit={createEquipment}
                  height={userStore.isExternalUser ? '12rem' : undefined}
                  componentType="textArea"
                  centerLabel={false}
                />
              </Box>
            </Box>
            <Line direction="vertical" margin="3rem" />
            <Box gap="1rem" width="40%">
              <FormCardSection>
                <Select
                  id="facility_id"
                  label="Facility"
                  value={equipmentStore.formValues.facility_id}
                  setValue={(value) => equipmentStore.updateFormValue('facility_id', value)}
                  onMore={nextFacilityPage}
                  options={facilityOptions}
                  error={equipmentStore.formErrors['facility_id']}
                  required
                />
              </FormCardSection>
              {userStore.isInternalUser && <Line margin="0.5rem" />}
              {userStore.isInternalUser && (
                <FormCardSection title="FSE">
                  <Select
                    id="fse_registration_status_id"
                    label="FSE Registration Status"
                    placeholder="Choose..."
                    value={equipmentStore.formValues.fse_registration_status_id}
                    setValue={(value) => equipmentStore.updateFormValue('fse_registration_status_id', value)}
                    options={(fseRegistrationStatuses ?? []).map((s) => ({ label: s.name, value: s.id }))}
                    error={equipmentStore.formErrors['fse_registration_status_id']}
                    required
                  />

                  <Input
                    id="fse_id"
                    label="FSE ID"
                    value={equipmentStore.formValues.fse_id ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('fse_id', value)}
                    error={equipmentStore.formErrors['fse_id']}
                    onSubmit={createEquipment}
                    required={equipmentStore.isApproved}
                  />

                  <Input
                    id="fse_ru"
                    label="FSE RU"
                    value={equipmentStore.formValues.fse_ru ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('fse_ru', value)}
                    error={equipmentStore.formErrors['fse_ru']}
                    onSubmit={createEquipment}
                  />
                </FormCardSection>
              )}
              <Line margin="0.5rem" />
              <FormCardSection title="Location">
                <Box row gap="1rem">
                  <Input
                    id="latitude"
                    label="Latitude"
                    value={equipmentStore.formValues.latitude}
                    type="number"
                    setValue={(value) => {
                      equipmentStore.updateFormValue('latitude', value);
                      setIsUserLocation(true);
                    }}
                    error={equipmentStore.formErrors['latitude']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    placeholder="DD.DDDDDD°"
                    required
                  />
                  <Input
                    id="longitude"
                    label="Longitude"
                    value={equipmentStore.formValues.longitude}
                    type="number"
                    setValue={(value) => {
                      equipmentStore.updateFormValue('longitude', value);
                      setIsUserLocation(true);
                    }}
                    error={equipmentStore.formErrors['longitude']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    placeholder="DDD.DDDDDD°"
                    required
                  />
                </Box>
              </FormCardSection>
            </Box>
          </Box>
        </Form>
      </FormCard>
    </FormPage>
  );
});
