import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ClientList, ListPage, PageHeader } from '/src/components';
import { config } from '/src/config';
import { useUserStore } from '/src/context';
import { getPageTitle } from '/src/utils';

export const ListClientsPage = observer(() => {
  const { isInternalUser, isAdminUser, isClientUser } = useUserStore();

  const isLoading = false;

  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Clients');
  }, []);

  return (
    <ListPage
      title="Clients"
      pageHeader={
        <PageHeader
          title="Clients"
          showAddButton={isInternalUser || (isAdminUser && isClientUser)}
          addButtonLabel="ADD CLIENT"
          addButtonUrl="/clients/create"
        />
      }
      isLoading={isLoading}
    >
      <ClientList showSearchInput showFilters />
    </ListPage>
  );
});
