import dayjs from 'dayjs';
import { Equipment, EquipmentUsage, Facility, Region, TClientId, User } from '../lib/models';
import { CountryId } from '../lib/types';

export const isValidEmail = (email?: string) => {
  if (!email) return false;

  const [, fqd] = email.split('@');
  if (!fqd) return false;

  const [, tld] = fqd.split('.');

  // one-character TLDs are possible, but none currently exist: https://www.iana.org/domains/root/db
  if (!tld || tld.length === 1) return false;

  return true;
};

export const userHasClient = (user: User, clientId: TClientId) => {
  return user.clients?.findIndex((client) => client.id === clientId) !== -1;
};

export const getIsCanada = (region?: Region) => region?.country_id === CountryId.Canada;

export const getIsUsageEditable = (isExternalUser: boolean, usage?: EquipmentUsage) =>
  usage
    ? isExternalUser
      ? !usage.is_finalized && !usage.client_reporting_period.is_client_locked
      : !usage.is_finalized
    : false;

export const facilityIsOrWa = (facility?: Facility) =>
  facility?.address_region?.short_code?.toLowerCase() === 'or' ||
  facility?.address_region?.short_code?.toLowerCase() === 'wa';

export const equipmentIsRetired = (equipment?: Equipment) =>
  equipment?.last_active_reporting_quarter
    ? dayjs(equipment?.last_active_reporting_quarter).isSame(new Date()) ||
      dayjs(equipment?.last_active_reporting_quarter).isBefore(new Date())
    : false;

export const updateFormValue = (
  key: string,
  value: unknown,
  formValues: Record<string, unknown>,
  setFormValues: (formValues: Record<string, unknown>) => void,
  formErrors: Record<string, string>,
  setFormErrors: (formErrors: Record<string, string>) => void
) => {
  setFormValues({
    ...formValues,
    [key]: value,
  });
  if (formErrors[key]) {
    setFormErrors({
      ...formErrors,
      [key]: '',
    });
  }
};

/**
 * Unwraps a MobX ProxyObject and returns the value inside
 */
export const unwrap = (obj: Record<string, unknown> | unknown[]) => JSON.parse(JSON.stringify(obj));