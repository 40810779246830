import { BoxProps } from 'grommet';
import { Filter } from 'grommet-icons';
import React, { useState } from 'react';
import { Box, Card, CardBody, CardHeader, Input, Select, Text } from '/src/components';
import { FilterItem } from '/src/lib/types';
import { pxToRem } from '/src/utils';

export const FiltersDropdown: React.FC<FiltersDropdownProps> = (props) => {
  const { isFiltering, onSubmit, onClear, filters, ...boxProps } = props;

  const [isApplyFocused, setIsApplyFocused] = useState(false);
  const [isClearFocused, setIsClearFocused] = useState(false);

  return (
    <Card width={pxToRem(300)} {...boxProps}>
      <CardHeader
        pad={{ horizontal: 'medium', vertical: 'small' }}
        title="Filters"
        icon={<Filter size="24px" color="brand" />}
      />
      <CardBody pad="1.5rem" gap="medium">
        {filters.map((filter) => (
          <Box key={filter.label} gap="xsmall" {...boxProps}>
            {filter.type === 'input' && (
              <Input
                label={filter.label}
                setValue={filter.setValue}
                onSubmit={onSubmit}
                value={filter.value}
                fontFamily="Lato, sans-serif"
                disabled={filter.disabled}
                hideOptionalText
              />
            )}
            {(!filter.type || filter.type === 'select') && !!filter.options && (
              <Select
                label={filter.label}
                placeholder={'Choose...'}
                options={filter.options}
                value={filter.value}
                setValue={filter.setValue}
                fontFamily="Lato, sans-serif"
                disabled={filter.disabled}
                hideOptionalText
              />
            )}
          </Box>
        ))}
        <Box direction="row" justify="between" gap="xsmall">
          <Box
            justify="center"
            fill="horizontal"
            background={isApplyFocused ? 'button-hover' : 'accent-1'}
            elevation={isApplyFocused ? 'small' : undefined}
            pad="0.75rem"
            borderRadius="6px"
            onMouseOver={() => setIsApplyFocused(true)}
            onMouseOut={() => setIsApplyFocused(false)}
            onClick={() => onSubmit()}
          >
            <Text alignSelf="center" color="white" size="1rem" weight={500}>
              {!isFiltering ? 'APPLY' : 'APPLYING...'}
            </Text>
          </Box>
          <Box
            justify="center"
            fill="horizontal"
            pad="0.75rem"
            background={isClearFocused ? 'light-5' : 'light-4'}
            elevation={isClearFocused ? 'small' : undefined}
            border={{ color: 'light-2', size: '0.05rem' }}
            borderRadius="6px"
            onMouseOver={() => setIsClearFocused(true)}
            onMouseOut={() => setIsClearFocused(false)}
            onClick={() => onClear()}
          >
            <Text alignSelf="center" size="1rem" weight={500}>
              CLEAR
            </Text>
          </Box>
        </Box>
      </CardBody>
    </Card>
  );
};

export type FiltersDropdownProps = BoxProps & {
  isFiltering: boolean;
  onSubmit: () => void;
  onClear: () => void;
  filters: FilterItem[];
  width?: BoxProps['width'];
};
