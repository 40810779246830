import { Button } from 'grommet';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Box, BoxProps, Line, Text, TextProps } from '/src/components';
import { useSearchStore } from '/src/context';
import { formatNumber } from '/src/utils';

export const SearchResultsBar: React.FC<SearchResultsBarProps> = observer((props) => {
  const { maxItems = 10, textSize = 'small', ...boxProps } = props;

  const searchStore = useSearchStore();

  const totalText = useMemo(() => {
    if (!searchStore.resultsMetadata) return;

    return searchStore.resultsMetadata.total ? (
      <Text size={textSize}>
        Found <b>{formatNumber(searchStore.resultsMetadata.total, true)}</b> result
        {searchStore.resultsMetadata.total > 1 ? 's' : ''}
      </Text>
    ) : (
      <></>
    );
  }, [searchStore.resultsMetadata, maxItems, textSize]);

  return !!searchStore.results && !!searchStore.resultsMetadata ? (
    <Box
      animation={{ type: 'slideDown', duration: 300, size: 'large' }}
      direction="row"
      pad={{ horizontal: 'small', bottom: 'small' }}
      justify="between"
      align="center"
      {...boxProps}
    >
      <Box>{totalText}</Box>
      <Box direction="row" gap="xsmall">
        <Button plain onClick={() => searchStore.clear()} tip="Clear the current results">
          <Box pad={{ horizontal: 'xsmall' }}>
            <Text size={textSize} color="accent-1">
              Clear
            </Text>
          </Box>
        </Button>
        <Line direction="vertical" margin="none" color="text" />
        <Button plain onClick={() => searchStore.reset()} tip="Reset the search box">
          <Box pad={{ horizontal: 'xsmall' }}>
            <Text size={textSize} color="accent-1">
              Reset
            </Text>
          </Box>
        </Button>
      </Box>
    </Box>
  ) : (
    <></>
  );
});

export type SearchResultsBarProps = BoxProps & {
  maxItems?: number;
  textSize?: TextProps['size'];
};
