import { Program, TCountryId, TRegionId } from '/src/lib/models';
import { Nullable } from '/src/lib/types';

/**
 * List Programs
 */
export type TListProgramsRequest = {
  region_id?: TRegionId;
  country_id?: Nullable<TCountryId>;
};

export type TListProgramsResponse = Program[];
