import { DataTable } from 'grommet';
import React from 'react';
import { DataTableHeader, DataTableItem, Link, LoadingSpinner, Text } from '/src/components';
import { SalesCommission } from '/src/lib/models';
import { getQuarterFromDateString } from '/src/utils';

export type SalesCommissionsListProps = {
  salesCommissions?: SalesCommission[];
};

export const SalesCommissionsList: React.FC<SalesCommissionsListProps> = ({ salesCommissions }) => {
  return !salesCommissions ? (
    <LoadingSpinner />
  ) : (
    <DataTable
      pad={{ horizontal: '1.5rem' }}
      columns={[
        {
          property: 'name',
          primary: true,
          size: '35%',
          header: <DataTableHeader>NAME</DataTableHeader>,
          render: (salesCommission) => (
            <Link to={`/users/${salesCommission.user.id}`}>
              <Text size="medium" weight={500} color="accent-1">
                {salesCommission.user.name}
              </Text>
            </Link>
          ),
        },
        {
          property: 'percent_rate',
          size: '35%',
          header: <DataTableHeader>PERCENTAGE</DataTableHeader>,
          render: (salesCommission) => (
            <DataTableItem boxProps={{ pad: { vertical: '1rem' } }}>{`${salesCommission.percent_rate}%`}</DataTableItem>
          ),
        },
        {
          property: 'start_reporting_quarter',
          size: '30%',
          header: <DataTableHeader>EFFECTIVE START DATE</DataTableHeader>,
          render: (salesCommission) => (
            <DataTableItem>{getQuarterFromDateString(salesCommission.start_reporting_quarter)}</DataTableItem>
          ),
        },
      ]}
      data={salesCommissions}
      background={['light-6', 'white']}
    />
  );
};
