import dayjs from 'dayjs';
import { Accordion, AccordionPanel, DataTable, ResponsiveContext, TextArea } from 'grommet';
import { Checkmark, CircleAlert } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageNotFound from '../404';
import { ApiError, EquipmentService, ModelAuditLogService } from '/src/api';
import {
  AuditLogEventTypeIcon,
  AuditLogIcon,
  Box,
  BreadcrumbNav,
  EntityIcon,
  FormCard,
  FormCardSection,
  FormPage,
  Line,
  Link,
  LoadingSpinner,
  Text,
} from '/src/components';
import { config } from '/src/config';
import { useGlobalStore, useModelAuditLogStore } from '/src/context';
import { Equipment, ModelAuditLog, ModelName, TModelAuditLogId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { ModelAuditLogEventType, ModelAuditLogState, TModelAuditLogChangeData } from '/src/lib/types';
import { fromCamelCase, getModelLabel, getModelUrl, getPageTitle } from '/src/utils';

export const ModelAuditLogDetailsPage = observer(() => {
  /** Context **/
  const globalStore = useGlobalStore();
  const navigate = useNavigate();
  const modelAuditLogStore = useModelAuditLogStore();
  const screenSize = useContext(ResponsiveContext);
  const params = useParams();

  /** State **/
  const [isUpdating, setIsUpdating] = useState(false);
  const [usageEquipment, setUsageEquipment] = useState<Equipment>();
  const [modelAuditLog, setModelAuditLog] = useState<ModelAuditLog>();
  const [isInvalidParams, setIsInvalidParams] = useState(false);

  /** Computed **/
  const modelAuditLogId = parseInt(params.model_audit_log_id ?? '');
  const isMobile = screenSize === 'small' || screenSize === 'xsmall';
  const isResolvable = modelAuditLog?.model_audit_log_state_id !== null;
  const isResolved =
    modelAuditLog?.model_audit_log_state_id !== null &&
    modelAuditLog?.model_audit_log_state_id === ModelAuditLogState.Resolved;

  const modelUrl = usageEquipment
    ? getModelUrl(usageEquipment, ModelName.Equipment)
    : modelAuditLog
      ? getModelUrl(modelAuditLog.model, modelAuditLog.model_name as ModelName)
      : '';

  const modelLabel = usageEquipment
    ? getModelLabel(usageEquipment, ModelName.Equipment)
    : modelAuditLog
      ? getModelLabel(modelAuditLog.model, modelAuditLog.model_name as ModelName)
      : '';

  const breadcrumbModelName =
    modelAuditLog?.model_audit_log_event_type.name === ModelAuditLogEventType.Create
      ? 'Creation'
      : modelAuditLog?.model_audit_log_event_type.name === ModelAuditLogEventType.Delete
        ? 'Deletion'
        : modelAuditLog?.model_audit_log_event_type.name;

  const isLoading = isUpdating;

  const changedValues = useMemo<TModelAuditLogChangeData>(() => {
    if (!modelAuditLog) return [];
    return Object.keys(modelAuditLog.old_data ?? {}).map((field) => ({
      field,
      old_value: modelAuditLog.old_data?.[field] ?? null,
      new_value: modelAuditLog.new_data?.[field] ?? null,
    }));
  }, [modelAuditLog]);

  /** Methods **/
  const fetchModelAuditLog = async (modelAuditLogId: TModelAuditLogId) => {
    try {
      const modelAuditLog = await ModelAuditLogService.get({ id: modelAuditLogId });

      if (!modelAuditLog.model && modelAuditLog.old_data) {
        const equipmentId =
          typeof modelAuditLog.old_data.equipmentId === 'string'
            ? parseInt(modelAuditLog.old_data.equipmentId)
            : modelAuditLog.old_data.equipmentId;
        if (equipmentId) {
          const equipment = await EquipmentService.get({ id: equipmentId });
          setUsageEquipment(equipment);
        }
      }

      setModelAuditLog(modelAuditLog);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchAuditLog.error);
    }
  };

  const replaceAliases = (input: string) => {
    let output = input;
    Object.keys(modelAuditLogStore.fieldAliases).forEach((key) => {
      if (input.includes(key)) output = output.replace(key, modelAuditLogStore.fieldAliases[key]);
    });
    return output;
  };

  const markAsResolved = async () => {
    if (!modelAuditLog) return;

    try {
      setIsUpdating(true);
      await ModelAuditLogService.updateState({
        model_audit_log_id: modelAuditLog.id,
        model_audit_log_state_id: ModelAuditLogState.Resolved,
      });
      toast.success(toastMessages.resolveAuditLog.success);
      navigate('/change-logs');
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.resolveAuditLog.error);
    } finally {
      setIsUpdating(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Change Logs');
  }, []);

  useEffect(() => {
    if (modelAuditLogId) {
      fetchModelAuditLog(modelAuditLogId);
    }
  }, [modelAuditLogId]);

  //404 Redirect
  useEffect(() => {
    if (!isLoading) {
      const invalid = Number.isNaN(modelAuditLogId) || modelAuditLog?.id === undefined;
      setIsInvalidParams(invalid);
    }
  }, [modelAuditLogId, modelAuditLog, isLoading]); 

  /** Render **/
  return isInvalidParams ? (
    <PageNotFound />
  ) : (
    <FormPage
      title="Log Details"
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[{ name: 'Change Logs', link: '/change-logs' }]}
          currentPageName={
            modelAuditLog ? `${fromCamelCase(modelAuditLog.model_name)} ${breadcrumbModelName}` : 'Log Details'
          }
        />
      }
      isLoading={isLoading}
    >
      <FormCard
        width="40rem"
        title={
          modelAuditLog
            ? modelAuditLog.model_name === ModelName.EquipmentUsage
              ? `${fromCamelCase(modelAuditLog.model_name)} Log`
              : `${getModelLabel(modelAuditLog.model, modelAuditLog.model_name as ModelName)}`
            : 'Log'
        }
        icon={<AuditLogIcon />}
        onSubmit={() => markAsResolved()}
        isLoading={isLoading}
        hideSaveButton={isResolved}
        hideRequiredText
        saveButtonLabel="MARK AS RESOLVED"
        saveButtonLoadingLabel="SAVING..."
      >
        {isLoading && <LoadingSpinner />}
        {!isLoading && modelAuditLog && (
          <Box>
            <FormCardSection>
              <Box direction={isMobile ? 'column' : 'row'} gap="medium">
                {/* Status */}
                <Box flex="grow" gap="xsmall">
                  <Box gap="xsmall">
                    <Text color="dark-4" size="small" toUpperCase>
                      STATUS
                    </Text>
                    <Box direction="row" align="center" gap="xsmall">
                      {isResolvable &&
                        (isResolved ? <Checkmark size="18px" color="green" /> : <CircleAlert size="18px" />)}
                      <Text
                        fontFamily="Lato, sans-serif"
                        color={isResolved ? 'green' : undefined}
                        weight={isResolved ? 500 : undefined}
                      >
                        {isResolvable ? (isResolved ? 'Resolved' : 'Unresolved') : '—'}
                      </Text>
                    </Box>
                  </Box>
                </Box>

                {/* Resolved By */}
                {modelAuditLog.is_resolved && (
                  <Box flex="grow" gap="xsmall">
                    <Text color="dark-4" size="small" toUpperCase>
                      RESOLVED BY
                    </Text>
                    <Link to={`/users/${modelAuditLog.user.id}`} textDecoration="none">
                      <Text color="accent-1" size="medium" fontFamily="Lato, sans-serif" lineHeight="1rem">
                        {isResolvable ? modelAuditLog.user.name : '—'}
                      </Text>
                    </Link>
                  </Box>
                )}

                {/* Event Type */}
                <Box flex="grow" gap="xsmall">
                  <Text color="dark-4" size="small" toUpperCase>
                    EVENT TYPE
                  </Text>
                  <Box direction="row" align="center" gap="xsmall">
                    <AuditLogEventTypeIcon
                      auditLogEventTypeName={modelAuditLog.model_audit_log_event_type.name}
                      size="16px"
                      color="text"
                    />
                    <Text size="medium" fontFamily="Lato, sans-serif">
                      {modelAuditLog.model_audit_log_event_type.name}
                    </Text>
                  </Box>
                </Box>

                {/* Model Type */}
                <Box flex="grow" gap="xsmall">
                  <Text color="dark-4" size="small" toUpperCase>
                    MODEL
                  </Text>
                  <Box direction="row" align="center" gap="xsmall">
                    <EntityIcon entityName={modelAuditLog.model_name} size="18px" color="text" />
                    <Text size="medium" fontFamily="Lato, sans-serif">
                      {fromCamelCase(modelAuditLog.model_name)}
                    </Text>
                  </Box>
                </Box>

                {/* Model Name */}
                <Box flex="grow" gap="xsmall">
                  <Text color="dark-4" size="small" toUpperCase>
                    {usageEquipment ? ModelName.Equipment : fromCamelCase(modelAuditLog.model_name)} Name
                  </Text>
                  <Link to={modelUrl}>
                    <Text size="medium" fontFamily="Lato, sans-serif" color="accent-1">
                      {modelLabel || '—'}
                    </Text>
                  </Link>
                </Box>

                {/* Created/Updated By */}
                <Box flex="grow" gap="xsmall">
                  <Text color="dark-4" size="small" toUpperCase>
                    {modelAuditLog.model_audit_log_event_type.name}D BY
                  </Text>
                  {modelAuditLog.user ? (
                    <Box>
                      <Link to={`/users/${modelAuditLog.user.id}`} textDecoration="none">
                        <Text color="accent-1" size="medium" fontFamily="Lato, sans-serif" lineHeight="1rem">
                          {modelAuditLog.user.name}
                        </Text>
                      </Link>
                      <Text size="medium" fontFamily="Lato, sans-serif" lineHeight="1.25rem">
                        {modelAuditLog.user.email}
                      </Text>
                      <Text size="small" fontFamily="Lato, sans-serif" lineHeight="1rem">
                        {modelAuditLog.user.phone}
                      </Text>
                    </Box>
                  ) : (
                    <Text>—</Text>
                  )}
                </Box>

                {/* Created/Updated User Role */}
                <Box flex="grow" gap="xsmall">
                  <Text color="dark-4" size="small" toUpperCase>
                    USER ROLE
                  </Text>
                  <Text size="medium" fontFamily="Lato, sans-serif">
                    {globalStore.roles.find((role) => role.id === modelAuditLog?.role_id)?.name || '—'}
                  </Text>
                </Box>

                {/* Date */}
                <Box flex="grow" gap="xsmall">
                  <Text color="dark-4" size="small" toUpperCase>
                    DATE
                  </Text>
                  <Text fontFamily="Lato, sans-serif">{dayjs(modelAuditLog.created_at).format('MM/DD/YYYY')}</Text>
                </Box>

                <Box flex="grow" gap="xsmall">
                  <Text color="dark-4" size="small" toUpperCase>
                    TIME
                  </Text>
                  <Text fontFamily="Lato, sans-serif">{dayjs(modelAuditLog.created_at).format('h:mm A')}</Text>
                </Box>
              </Box>
              <Accordion border={undefined}>
                <AccordionPanel
                  label={
                    <Box>
                      <Text size="small" color="dark-4">
                        REQUEST METADATA
                      </Text>
                    </Box>
                  }
                >
                  <Box pad={{ top: 'small' }} gap="small">
                    <Box direction="row">
                      <Box flex="grow" gap="xsmall">
                        <Text size="small" color="dark-4">
                          IP
                        </Text>
                        <Text fontFamily="Lato, sans-serif">{modelAuditLog.request_ip}</Text>
                      </Box>
                      <Box flex="grow" gap="xsmall">
                        <Text size="small" color="dark-4">
                          METHOD
                        </Text>
                        <Text fontFamily="Lato, sans-serif">{modelAuditLog.request_method}</Text>
                      </Box>
                      <Box flex="grow" gap="xsmall">
                        <Text size="small" color="dark-4">
                          URL
                        </Text>
                        <Text fontFamily="Lato, sans-serif">{modelAuditLog.request_url}</Text>
                      </Box>
                      <Box width="50%" gap="xsmall">
                        <Text size="small" color="dark-4">
                          BODY
                        </Text>
                        {modelAuditLog.request_body ? (
                          <TextArea
                            name="request_body"
                            value={
                              typeof modelAuditLog.request_body === 'object'
                                ? JSON.stringify(modelAuditLog.request_body)
                                : modelAuditLog.request_body
                            }
                            resize={false}
                            rows={10}
                            readOnly
                          />
                        ) : (
                          <Text color="light-1">
                            <i>None</i>
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </AccordionPanel>
              </Accordion>
            </FormCardSection>
            {!!changedValues?.length && <Line />}
            {!!changedValues?.length && (
              <FormCardSection title="Data Changes">
                <Box border={{ side: 'all', size: '0.5px', color: 'light-2' }}>
                  <DataTable
                    data={changedValues}
                    columns={[
                      {
                        property: 'field',
                        primary: true,
                        size: '45%',
                        header: (
                          <Text color="dark-4" size="small">
                            FIELD
                          </Text>
                        ),
                        render: ({ field }) => (
                          <Text fontFamily="Lato, sans-serif">{replaceAliases(fromCamelCase(field))}</Text>
                        ),
                      },
                      {
                        property: 'old_value',
                        header: (
                          <Text color="dark-4" size="small">
                            OLD VALUE
                          </Text>
                        ),
                        render: ({ old_value }) => (
                          <Box>
                            {!!old_value && <Text fontFamily="Lato, sans-serif">{old_value?.toString()}</Text>}
                            {!old_value && (
                              <Text size="small" color="light-1" fontFamily="Lato, sans-serif">
                                <i>None</i>
                              </Text>
                            )}
                          </Box>
                        ),
                      },
                      {
                        property: 'new_value',
                        header: (
                          <Text color="dark-4" size="small">
                            NEW VALUE
                          </Text>
                        ),
                        render: ({ new_value }) => (
                          <Box>
                            {!!new_value && <Text fontFamily="Lato, sans-serif">{new_value?.toString()}</Text>}
                            {!new_value && (
                              <Text size="small" color="light-1" fontFamily="Lato, sans-serif">
                                <i>None</i>
                              </Text>
                            )}
                          </Box>
                        ),
                      },
                    ]}
                    border={{ side: 'all', size: '0.5px', color: 'light-2' }}
                  />
                </Box>
              </FormCardSection>
            )}
          </Box>
        )}
      </FormCard>
    </FormPage>
  );
});
