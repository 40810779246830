import { Main } from 'grommet';
import { useEffect } from 'react';
import { config } from '../config';
import { getPageTitle } from '../utils';
import { Box, FooterNav, HeaderNav } from '/src/components';

const ApiData = () => {
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'API Data');
  }, []);

  return (
    <Box style={{ minHeight: '100vh' }} background="light">
      <HeaderNav />
      <Main>
        <Box flex="grow">
          <iframe
            id="api-data"
            title="API Data"
            style={{
              display: 'flex',
              flexBasis: 'auto',
              flexDirection: 'column',
              flexGrow: '1',
              flexShrink: '0',
              border: 'none',
              margin: 0,
              padding: 0,
            }}
            src={config.urls.apiDataUrl}
          ></iframe>
        </Box>
      </Main>
      <Box>
        <FooterNav />
      </Box>
    </Box>
  );
};

export default ApiData;
