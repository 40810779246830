import { ResponsiveContext } from 'grommet';
import React, { useContext, useRef } from 'react';
import { Box, BoxProps, Link, Text } from '/src/components';
import { getIsMobile } from '/src/utils';

export const BreadcrumbNav: React.FC<BreadcrumbNavProps> = (props) => {
  const { previousPages, currentPageName, ...boxProps } = props;

  const screenSize = useContext(ResponsiveContext);
  const isMobile = getIsMobile(screenSize);

  //const [focusedItem, setFocusedItem] = useState<string>('');

  const textSize = useRef('small');

  return (
    <Box
      pad={{ horizontal: isMobile ? '3.75rem' : '8rem', vertical: '1rem' }}
      border={{ side: 'bottom', size: 'small', color: 'light-2' }}
      {...boxProps}
    >
      <Box direction="row" gap="xsmall">
        {previousPages.map(({ name, link, color, size, weight }, i) => (
          <Box key={`${name}-${i}`} direction="row" gap="xsmall" height="large">
            {link ? (
              <Link
                to={link}
                textDecoration="none"
                // onMouseOver={() => setFocusedItem(name)}
                // onMouseOut={() => setFocusedItem('')}
              >
                <Box>
                  <Text size={size || textSize.current} color={color || 'dark-1'} weight={weight} letterSpacing="0.6px">
                    {name.toUpperCase()}
                  </Text>
                </Box>
              </Link>
            ) : (
              <Text size={size || textSize.current} color={color || 'dark-1'} weight={weight} letterSpacing="0.6px">
                {name.toUpperCase()}
              </Text>
            )}
            <Text size={textSize.current} color="light-2">
              /
            </Text>
          </Box>
        ))}
        <Text size={textSize.current} color="dark-1" letterSpacing="0.6px">
          {currentPageName.toUpperCase()}
        </Text>
      </Box>
    </Box>
  );
};

export type BreadcrumbNavProps = BoxProps & {
  previousPages: {
    name: string;
    link?: string;
    color?: string;
    size?: string;
    weight?: number;
  }[];
  currentPageName: string;
};
