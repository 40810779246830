import { ClientFile, TClientFileId } from '/src/lib/models';
import { TResponseMetadata } from '/src/lib/types';

/**
 * List Client Files
 */
export type TListClientFilesRequest = {
  page?: number;
};

export type TListClientFilesResponse = {
  meta: TResponseMetadata;
  data: ClientFile[];
};

/**
 * Get Client File
 */
export type TGetClientFileRequest = {
  id: TClientFileId;
};

export type TGetClientFileResponse = ClientFile;

/**
 * Create Client File
 */
export type TCreateClientFileRequest = {
  client_id: number;
  name: string;
  type: string;
  file: File; 
};

export type TCreateClientFileResponse = ClientFile;

/**
 * Update Client File
 */
export type TUpdateClientFileRequest = {
  id: TClientFileId;
  client_id: number;
  name: string;
  type: string;
  file: File | null; 
};

export type TUpdateClientFileResponse = ClientFile;