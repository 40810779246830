import { Box as GrommetBox, BoxProps as GrommetBoxProps } from 'grommet';
import React, { PropsWithChildren } from 'react';

export const Box: React.FC<BoxProps> = (props) => {
  const {
    children,
    borderRadius,
    lineHeight,
    fontFamily,
    letterSpacing,
    cursor,
    style,
    transitionDuration,
    transitionProperty,
    transitionTimingFunction,
    opacity,
    hidden,
    row,
    ...boxProps
  } = props;

  const customStyles: React.CSSProperties = {};
  if (borderRadius) customStyles.borderRadius = borderRadius;
  if (lineHeight) customStyles.lineHeight = lineHeight;
  if (fontFamily) customStyles.fontFamily = fontFamily;
  if (letterSpacing) customStyles.letterSpacing = letterSpacing;
  if (cursor) customStyles.cursor = cursor;
  if (transitionDuration) customStyles.transitionDuration = transitionDuration;
  if (transitionProperty) customStyles.transitionProperty = transitionProperty;
  if (transitionTimingFunction) customStyles.transitionTimingFunction = transitionTimingFunction;
  if (opacity) customStyles.opacity = opacity;

  return (
    <GrommetBox style={{ ...customStyles, ...style }} hidden={hidden} direction={row ? 'row' : undefined} {...boxProps}>
      {children}
    </GrommetBox>
  );
};

export type BoxProps = PropsWithChildren &
  GrommetBoxProps & {
    borderRadius?: string;
    lineHeight?: string;
    fontFamily?: string;
    letterSpacing?: string;
    cursor?: string;
    style?: React.CSSProperties;
    id?: string;
    color?: string;
    transitionDuration?: string;
    transitionProperty?: string;
    transitionTimingFunction?: string;
    opacity?: number;
    hidden?: boolean;
    row?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
