import dayjs from 'dayjs';
import { CheckBox, ResponsiveContext, Spinner } from 'grommet';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ApiError,
  ClientReportingPeriodService,
  TListClientReportingPeriodsRequest,
  TUpdateMeteredEquipmentUsageRequest,
  TUpdateUnmeteredEquipmentUsageRequest,
} from '/src/api';
import {
  AddButton,
  Box,
  Card,
  CardBody,
  CardHeader,
  EquipmentUsageIcon,
  FormCardSection,
  Input,
  ReportingPeriodDropdown,
  Select,
  Text,
  UsagesDataTable,
} from '/src/components';
import { useEquipmentStore, useEquipmentUsageStore, useGlobalStore, useUserStore } from '/src/context';
import { errorMessages } from '/src/lib/errors';
import { ClientReportingPeriod, Equipment, EquipmentUsage, TEquipmentUsageId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import {
  CountryId,
  EquipmentUsageDataForm,
  FormMetadata,
  MeteredEquipmentUsageDataForm,
  UnmeteredEquipmentUsageDataForm,
} from '/src/lib/types';
import {
  filterSelectOptions,
  getFormErrors,
  getIsMobile,
  getIsUsageEditable,
  getPeriodFromDateString,
  pxToRem,
} from '/src/utils';

export type EquipmentUsagesListProps = {
  equipment: Equipment;
};

export const EquipmentUsagesList: React.FC<EquipmentUsagesListProps> = observer((props) => {
  const { equipment } = props;

  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const equipmentStore = useEquipmentStore();
  const equipmentUsageStore = useEquipmentUsageStore();
  const screenSize = useContext(ResponsiveContext);

  /** Refs **/
  const defaultFormValues = useRef<EquipmentUsageDataForm>(
    equipment.is_metered
      ? { fuel_pathway_id: undefined, is_book_and_claim_applied: false, total_kwh: '0' }
      : {
        fuel_pathway_id: undefined,
        is_book_and_claim_applied: false,
        is_paused: false,
        battery_capacity_rating_ah: '',
        voltage: '',
        charge_cycles_per_shift: '0',
        shifts_per_day: '0',
        work_days_per_quarter: '0',
        percent_charger_efficiency_rating: '0',
        percent_charge_return_factor: '0',
        percent_depth_of_discharge: '0',
      }
  );

  /** State **/
  const [isFetchingUsages, setIsFetchingUsages] = useState(true);
  const [isFetchingPeriods, setIsFetchingPeriods] = useState(true);
  const [isFetchingPathways, setIsFetchingPathways] = useState(true);
  const [isUpdatingCurrent, setIsUpdatingCurrent] = useState(false);
  const [reportingPeriods, setReportingPeriods] = useState<ClientReportingPeriod[]>();
  const [selectedReportingPeriod, setSelectedReportingPeriod] = useState<ClientReportingPeriod>();

  const [oldWorkDaysValue, setOldWorkDaysValue] = useState<string | number>();
  const [currentUsage, setCurrentUsage] = useState<EquipmentUsage>();
  const [formValues, setFormValues] = useState<EquipmentUsageDataForm>(defaultFormValues.current);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Computed **/
  const isMobile = getIsMobile(screenSize);
  const isLoading = isFetchingPeriods || isFetchingUsages || isFetchingPathways;
  const selectedIndex = reportingPeriods?.findIndex((p) => p.id === selectedReportingPeriod?.id) ?? -1;
  const previousPeriod = selectedIndex > -1 ? reportingPeriods?.[selectedIndex + 1] : undefined;
  const nextPeriod = selectedIndex > -1 ? reportingPeriods?.[selectedIndex - 1] : undefined;
  const isMetered = equipment.is_metered;
  const isCanada = equipment?.facility?.address_region?.country_id === CountryId.Canada;
  const isEditable = getIsUsageEditable(userStore.isExternalUser, currentUsage);
  const disabledMessage = currentUsage
    ? userStore.isExternalUser && selectedReportingPeriod?.is_client_locked
      ? 'This period has been locked and cannot be edited.'
      : 'This period has been finalized and can no longer be edited.'
    : '';

  /** Methods **/

  /**
   * Fetch Reporting Periods
   */
  const fetchReportingPeriods = async (equipment: Equipment) => {
    try {
      setIsFetchingPeriods(true);

      const request: TListClientReportingPeriodsRequest = {
        client_id: equipment.client_id,
        limit: 1000,
        reporting_period_type_id: equipment.reporting_period_type_id,
        start_reporting_quarter: equipment.first_active_reporting_quarter,
      };

      if (equipment.last_active_reporting_quarter) {
        request.end_reporting_quarter = equipment.last_active_reporting_quarter;
      }

      const { data } = await ClientReportingPeriodService.list(request);

      const periodData = data
        .reverse()
        .sort((a, b) =>
          a.start_reporting_quarter > b.start_reporting_quarter
            ? -1
            : a.start_reporting_quarter === b.start_reporting_quarter
              ? 0
              : 1
        );

      setReportingPeriods(periodData);

      if (periodData[0]) setSelectedReportingPeriod(periodData[0]);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listClientReportingPeriods.error);
    } finally {
      setIsFetchingPeriods(false);
    }
  };

  /**
   * Fetches page data.
   * @param equipment
   * @param reportingPeriod
   */
  const fetchPageData = async (equipment: Equipment, reportingPeriod: ClientReportingPeriod) => {
    try {
      setIsFetchingUsages(true);
      setIsFetchingPathways(true);

      const equipmentUsages = await equipmentUsageStore.listEquipmentUsages({
        equipment_id: equipment.id,
        client_reporting_period_id: reportingPeriod.id,
      });

      const usage = equipmentUsages?.find(
        (u) =>
          u.start_reporting_quarter === reportingPeriod.start_reporting_quarter &&
          u.end_reporting_quarter === reportingPeriod.end_reporting_quarter
      );
      setCurrentUsage(usage);

      if (usage) {
        const updatedFormValues: EquipmentUsageDataForm = isMetered
          ? ({
            is_book_and_claim_applied:
                usage.is_book_and_claim_applied ?? defaultFormValues.current.is_book_and_claim_applied,
            fuel_pathway_id: usage.fuel_pathway_id,
            total_kwh: usage.total_kwh ?? '0',
          } as MeteredEquipmentUsageDataForm)
          : ({
            is_book_and_claim_applied:
                usage.is_book_and_claim_applied ?? defaultFormValues.current.is_book_and_claim_applied,
            is_paused: usage.is_paused,
            fuel_pathway_id: usage.fuel_pathway_id,
            battery_capacity_rating_ah: usage.battery_capacity_rating_ah?.toString() ?? '',
            voltage: usage.voltage?.toString() ?? '',
            charge_cycles_per_shift: usage.charge_cycles_per_shift ?? '',
            shifts_per_day: usage.shifts_per_day ?? '',
            work_days_per_quarter: usage.is_paused ? '0' : usage.work_days_per_quarter ?? '0',
            percent_charger_efficiency_rating: usage.percent_charger_efficiency_rating?.toString() ?? '',
            percent_charge_return_factor: usage.percent_charge_return_factor?.toString() ?? '',
            percent_depth_of_discharge: usage.percent_depth_of_discharge?.toString() ?? '',
          } as UnmeteredEquipmentUsageDataForm);

        setFormValues({
          ...formValues,
          ...updatedFormValues,
        });

        if (equipment.facility?.program_id && equipment.facility?.address_region_id) {
          await equipmentUsageStore.fetchFuelPathways(
            equipment.facility.program_id,
            equipment.facility.address_region_id,
            usage.start_reporting_quarter,
            usage.end_reporting_quarter
          );
        }
      }
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listEquipmentUsages.error);
    } finally {
      setIsFetchingUsages(false);
      setIsFetchingPathways(false);
    }
  };

  /**
   * Filter Fuel Pathways by name. Used by the Fuel Pathways dropdown search box.
   * @param nameFilter
   */
  const searchPathways = (nameFilter: string) => {
    if (nameFilter === '') equipmentUsageStore.setFuelPathwayOptions(equipmentUsageStore.defaultFuelPathwayOptions);
    else {
      const filteredPathways = filterSelectOptions<number>(nameFilter, equipmentUsageStore.defaultFuelPathwayOptions);
      equipmentUsageStore.setFuelPathwayOptions(filteredPathways);
    }
  };

  /**
   * Update form data
   * @param key
   * @param value
   */
  const updateFormValue = (key: string, value: string | number | boolean) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  /**
   * Update Equipment Usage
   */
  const updateEquipmentUsage = async (equipmentUsageId: TEquipmentUsageId, formValues: EquipmentUsageDataForm) => {
    try {
      const formFields: FormMetadata = {
        fuel_pathway_id: { label: 'Fuel Pathway', required: true },
        battery_capacity_rating_ah: { label: 'Battery Capacity (Ah)', required: !isMetered },
        voltage: { label: 'Voltage', required: !isMetered },
      };

      const errors = getFormErrors(formFields, formValues);
      if (errors) {
        setFormErrors(errors);
        return;
      }

      setIsUpdatingCurrent(true);

      if (isMetered) {
        /* Update Metered */
        const values = formValues as MeteredEquipmentUsageDataForm;
        const newUsage: TUpdateMeteredEquipmentUsageRequest = {
          equipment_usage_id: equipmentUsageId,
          fuel_pathway_id: values.fuel_pathway_id || 0,
          is_book_and_claim_applied: values.is_book_and_claim_applied,
          total_kwh: parseFloat(values.total_kwh),
        };
        await equipmentUsageStore.updateMeteredEquipmentUsage(newUsage);
      } else {
        /* Update Unmetered */
        const values = formValues as UnmeteredEquipmentUsageDataForm;
        const newUsage: TUpdateUnmeteredEquipmentUsageRequest = {
          equipment_usage_id: equipmentUsageId,
          fuel_pathway_id: values.fuel_pathway_id || 0,
          is_book_and_claim_applied: values.is_book_and_claim_applied,
          is_paused: values.is_paused as boolean,
          battery_capacity_rating_ah: parseFloat(values.battery_capacity_rating_ah?.toString() ?? '0'),
          voltage: parseFloat(values.voltage?.toString() ?? '0'),
          charge_cycles_per_shift: values.charge_cycles_per_shift
            ? parseFloat(parseFloat(values.charge_cycles_per_shift).toFixed(2))
            : 0,
          shifts_per_day: values.shifts_per_day ? parseFloat(parseFloat(values.shifts_per_day).toFixed(2)) : 0,
          work_days_per_quarter: values.is_paused
            ? 0
            : values.work_days_per_quarter
              ? parseFloat(parseFloat(values.work_days_per_quarter).toFixed(2))
              : 0,
          percent_charger_efficiency_rating: values.percent_charger_efficiency_rating
            ? parseInt(values.percent_charger_efficiency_rating)
            : 0,
          percent_charge_return_factor: values.percent_charge_return_factor
            ? parseInt(values.percent_charge_return_factor)
            : 0,
          percent_depth_of_discharge: values.percent_depth_of_discharge
            ? parseInt(values.percent_depth_of_discharge)
            : 0,
        };
        await equipmentUsageStore.updateUnmeteredEquipmentUsage(newUsage);
      }

      toast.success(toastMessages.updateEquipmentUsage.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateEquipmentUsage.error);
    } finally {
      setIsUpdatingCurrent(false);
    }
  };

  const handleCheckBox = (isChecked: boolean) => {
    if (isChecked) {
      setOldWorkDaysValue(formValues.work_days_per_quarter as string | number);
    }
    updateFormValue('is_paused', isChecked);
  };

  /** Effects **/
  useEffect(() => {
    if (equipment?.client_id && !reportingPeriods) {
      fetchReportingPeriods(equipment);
    }
  }, [equipment?.client_id, reportingPeriods]);

  useEffect(() => {
    if (equipment && selectedReportingPeriod) {
      fetchPageData(equipment, selectedReportingPeriod);
    }
  }, [equipment, selectedReportingPeriod]);

  useEffect(() => {
    if (!isMetered) {
      if (formValues.is_paused) {
        updateFormValue('work_days_per_quarter', 0);
      } else if (oldWorkDaysValue !== undefined) {
        updateFormValue('work_days_per_quarter', oldWorkDaysValue);
      }
    }
  }, [isMetered, formValues.is_paused, oldWorkDaysValue]);

  /** Render **/
  return (
    <Card>
      <CardHeader title="Equipment Usage" icon={<EquipmentUsageIcon />}>
        {currentUsage && (
          <AddButton
            background="accent-1"
            color="white"
            style={{ opacity: isLoading || isUpdatingCurrent || !isEditable ? 0.5 : 1 }}
            label={'SAVE USAGE'}
            tip={`Save changes to ${getPeriodFromDateString(currentUsage.start_reporting_quarter, isCanada)}`}
            disabled={isLoading || isUpdatingCurrent || !isEditable}
            onClick={() => updateEquipmentUsage(currentUsage.id, formValues)}
          />
        )}
      </CardHeader>
      <CardBody
        gap={!isLoading && !currentUsage ? 'none' : '1.5rem'}
        pad={!isLoading && !currentUsage ? 'none' : undefined}
      >
        {isLoading && (
          <Box pad={{ vertical: 'medium' }} height={pxToRem(277)} fill="horizontal" justify="center" align="center">
            <Spinner size="large" />
          </Box>
        )}
        {!isLoading && equipment && reportingPeriods && selectedReportingPeriod && (
          <Box row justify="between" align="center" pad={!isLoading && !currentUsage ? 'medium' : undefined}>
            <Box gap="xsmall" justify="center">
              <Box row gap="xsmall">
                <Text size="xlarge" weight={500}>
                  Viewing
                </Text>
                <Text size="xlarge" weight={500} color="accent-1">
                  {selectedReportingPeriod &&
                    getPeriodFromDateString(selectedReportingPeriod.start_reporting_quarter, isCanada)}
                </Text>
              </Box>
              <Box>
                <Text size="medium" weight={300}>
                  {dayjs(selectedReportingPeriod?.start_reporting_quarter).format('MMMM DD, YYYY')} -{' '}
                  {dayjs(selectedReportingPeriod?.start_reporting_quarter)
                    .add(2, 'months')
                    .endOf('month')
                    .format('MMMM DD, YYYY')}
                </Text>
              </Box>
            </Box>
            {!isEditable && (
              <Box justify="center" align="center" pad="1rem" gap="xsmall">
                <Text fontFamily="Lato, sans-serif">{disabledMessage}</Text>
              </Box>
            )}
            <ReportingPeriodDropdown
              reportingPeriods={reportingPeriods}
              setSelectedReportingPeriod={setSelectedReportingPeriod}
              selectedReportingPeriod={selectedReportingPeriod}
              previousPeriod={previousPeriod}
              nextPeriod={nextPeriod}
              isCanada={isCanada}
              showNav
            />
          </Box>
        )}
        {!isLoading && (
          <Box gap="xsmall">
            {currentUsage && (
              <Box gap="medium">
                <FormCardSection>
                  <Box direction={isMobile ? 'column' : 'row'} gap="medium">
                    {!isMetered && (
                      <CheckBox
                        id="is_paused"
                        name="is_paused"
                        label="Paused for Seasonality"
                        checked={formValues.is_paused as boolean}
                        onChange={(e) => handleCheckBox(e.target.checked)}
                        disabled={!isEditable}
                      />
                    )}

                    <Select
                      id="fuel_pathway_id"
                      label="Fuel Pathway Code"
                      placeholder={!isFetchingPathways ? 'Choose...' : 'Loading...'}
                      value={!isFetchingPathways ? formValues.fuel_pathway_id : ''}
                      setValue={(value) => updateFormValue('fuel_pathway_id', value)}
                      options={equipmentUsageStore.fuelPathwayOptions}
                      error={formErrors['fuel_pathway_id']}
                      onSearch={searchPathways}
                      searchPlaceholder="Filter by Code..."
                      emptySearchMessage={errorMessages.fuelPathwayNameSearchNoResult}
                      required={equipmentStore.isApproved}
                      fill="horizontal"
                      disabled={!userStore.isAdminUser || !isEditable || isFetchingPathways}
                    />

                    {userStore.isInternalUser && (
                      <Select
                        id="is_book_and_claim_applied"
                        label="Book & Claim"
                        value={
                          formValues.is_book_and_claim_applied === null
                            ? undefined
                            : formValues.is_book_and_claim_applied
                              ? 'Applied'
                              : 'Not Applied'
                        }
                        setValue={(value) => updateFormValue('is_book_and_claim_applied', value === 'Applied')}
                        options={['Applied', 'Not Applied']}
                        error={formErrors['is_book_and_claim_applied']}
                        required={equipmentStore.isApproved}
                        disabled={!isEditable}
                        fill="horizontal"
                      />
                    )}

                    {!isMetered && (
                      <Input
                        id="battery_capacity_rating_ah"
                        label="Battery Capacity (Ah)"
                        value={formValues.battery_capacity_rating_ah as string}
                        setValue={(value) => updateFormValue('battery_capacity_rating_ah', value)}
                        error={formErrors['battery_capacity_rating_ah']}
                        onSubmit={() => updateEquipmentUsage(currentUsage.id, formValues)}
                        fill="horizontal"
                        type="number"
                        disabled={!isEditable}
                        required
                        hideOptionalText
                      />
                    )}
                    {!isMetered && (
                      <Input
                        id="voltage"
                        label="Voltage"
                        value={formValues.voltage as string}
                        setValue={(value) => updateFormValue('voltage', value)}
                        error={formErrors['voltage']}
                        onSubmit={() => updateEquipmentUsage(currentUsage.id, formValues)}
                        fill="horizontal"
                        type="number"
                        disabled={!isEditable}
                        required
                        hideOptionalText
                      />
                    )}
                  </Box>
                </FormCardSection>

                <UsagesDataTable
                  formValues={formValues}
                  updateFormValue={updateFormValue}
                  tableCells={
                    isMetered
                      ? [
                        {
                          label: 'Total kWh',
                          valueKey: 'total_kwh',
                          isEditable,
                          formatNumber: !isEditable,
                          unit: !isEditable ? 'kwh' : undefined,
                        },
                      ]
                      : [
                        {
                          label: 'Charge Cycles / Shift',
                          valueKey: 'charge_cycles_per_shift',
                          isEditable,
                        },
                        { label: 'Shifts / Day', valueKey: 'shifts_per_day', isEditable },
                        {
                          label: 'Work Days / Quarter',
                          valueKey: 'work_days_per_quarter',
                          isEditable: isEditable && !formValues.is_paused,
                        },
                        {
                          label: 'Charger Efficiency Rating',
                          valueKey: 'percent_charger_efficiency_rating',
                          isEditable,
                          unit: 'percent',
                          inputMode: 'numeric',
                        },
                        {
                          label: 'Charge Return Factor',
                          valueKey: 'percent_charge_return_factor',
                          isEditable,
                          unit: 'percent',
                          inputMode: 'numeric',
                        },
                        {
                          label: 'Depth of Discharge',
                          valueKey: 'percent_depth_of_discharge',
                          isEditable,
                          unit: 'percent',
                          inputMode: 'numeric',
                        },
                      ]
                  }
                  cellWidth="100%"
                  equipment={equipment}
                  equipmentUsages={[currentUsage]}
                  isCanada={isCanada}
                />
              </Box>
            )}

            {!currentUsage && (
              <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
                <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                  No usage data found.
                </Text>
              </Box>
            )}
          </Box>
        )}
      </CardBody>
    </Card>
  );
});
