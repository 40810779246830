import {
  MaskedInput as GrommetMaskedInput,
  MaskedInputProps as GrommetMaskedInputProps,
  Keyboard,
  Stack,
  TextInputProps,
} from 'grommet';
import React, { useState } from 'react';
import { Box, BoxProps, Text } from '/src/components';
import { pxToRem } from '/src/utils';

export type MaskedInputProps = BoxProps & {
  label: string;
  value: string | number | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (value: any) => void;
  componentType: 'number' | 'percent' | 'percentage';
  id?: string;
  name?: string;
  error?: string;
  type?: JSX.IntrinsicElements['input']['type'];
  placeholder?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  rows?: number;
  hideOptionalText?: boolean;
  hideIcon?: boolean;
  hideRequiredMarker?: boolean;
  centerLabel?: boolean;
  disabled?: boolean;
  disabledBackground?: BoxProps['background'];
  autoComplete?: TextInputProps['autoComplete'];
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
  mask?: GrommetMaskedInputProps['mask'];
  isActiveLabel?: boolean;
};

export const MaskedInput: React.FC<MaskedInputProps> = (props) => {
  const {
    label,
    value = Number.isNaN(props.value) ? '' : props.value,
    setValue,
    id,
    name,
    error,
    placeholder,
    type,
    required,
    minLength,
    maxLength,
    hideIcon,
    hideRequiredMarker,
    centerLabel = true,
    disabled,
    disabledBackground,
    autoComplete,
    componentType,
    onSubmit,
    isActiveLabel: _isActiveLabel,
    ...boxProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const isActiveLabel =
    _isActiveLabel ??
    ((value !== undefined && value !== null && value !== '') || isFocused || !centerLabel || placeholder || disabled);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      if (minLength) {
        if (typeof e.target.value === 'number' && (e.target.value as number).toString().length < minLength) return;
        else if (typeof e.target.value === 'string' && (e.target.value as string).length < minLength) return;
      }
      if (maxLength) {
        if (typeof e.target.value === 'number' && (e.target.value as number).toString().length > maxLength) return;
        else if (typeof e.target.value === 'string' && (e.target.value as string).length > maxLength) return;
      }
    }
    setValue(e.target.value);
  };

  const numberRegex = /^[0-9]*$/g;

  const renderNumberInput = (beforeDecimal = 1, afterDecimal = 4) => (
    <GrommetMaskedInput
      id={id}
      name={name || id}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      autoComplete={autoComplete}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      icon={
        !hideIcon ? (
          <Text size="20px" color="dark-1" weight={700} style={{ fontFamily: 'Lato, sans-serif' }}>
            $
          </Text>
        ) : undefined
      }
      reverse
      plain
      mask={[
        { length: [1, beforeDecimal + 1], regexp: /0/g, placeholder: '0' },
        { fixed: '.' },
        { length: [1, afterDecimal + 1], regexp: numberRegex },
      ]}
      style={{
        fontFamily: 'Lato, sans-serif',
        height: pxToRem(58),
        paddingTop: pxToRem(26),
        paddingBottom: pxToRem(10),
      }}
      className="hide-input-arrows"
    />
  );

  const renderPercentInput = (beforeDecimal = 2, afterDecimal = 2) => (
    <GrommetMaskedInput
      id={id}
      name={name || id}
      placeholder={placeholder}
      minLength={minLength}
      maxLength={maxLength}
      icon={
        <Text size="20px" color="dark-1" weight={700} style={{ fontFamily: 'Lato, sans-serif' }}>
          %
        </Text>
      }
      reverse
      type="number"
      className="percent-input hide-input-arrows"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      required={required}
      disabled={disabled}
      autoComplete={autoComplete}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      plain
      mask={[
        { length: [1, beforeDecimal + 1], regexp: numberRegex },
        { fixed: '.' },
        { length: [1, afterDecimal + 1], regexp: numberRegex },
      ]}
      style={{
        fontFamily: 'Lato, sans-serif',
        height: pxToRem(58),
        paddingTop: pxToRem(26),
        paddingBottom: pxToRem(10),
      }}
    />
  );

  const renderInputComponent = () => {
    switch (componentType) {
      case 'number':
        return renderNumberInput();
      case 'percent':
        return renderPercentInput();
      case 'percentage':
        return renderPercentInput();
      default:
        return <></>;
    }
  };

  const borderColor = error ? 'red' : isFocused ? 'accent-1' : 'light-5';

  return (
    <Box {...boxProps}>
      <Box
        border={disabled ? undefined : { color: borderColor, size: 'small' }}
        background={disabled ? disabledBackground || 'light-6' : undefined}
        round="6px"
        height={pxToRem(58)}
      >
        <Box round="6px" direction="row" fill="vertical">
          <Stack anchor={isActiveLabel ? 'top-left' : 'left'} guidingChild="last" fill="vertical">
            <Box pad={{ top: isActiveLabel ? 'xsmall' : undefined, left: 'small' }}>
              <Text size={isActiveLabel ? 'small' : 'medium'} color={isActiveLabel ? 'accent-1' : undefined}>
                {label}
                {required && !hideRequiredMarker && '*'}
              </Text>
            </Box>
            <Keyboard onEnter={onSubmit}>{renderInputComponent()}</Keyboard>
          </Stack>
        </Box>
      </Box>
      {error && (
        <Box row gap="small" margin={{ top: '0.5rem', left: '0.75rem' }}>
          <Text color="red" size="0.75rem">
            {error}
          </Text>
        </Box>
      )}
    </Box>
  );
};
