import { TextInput } from 'grommet';
import React from 'react';
import { Box, BoxProps } from '/src/components';

export const DataTableInputItem: React.FC<DataTableInputItemProps> = ({ value, setValue, boxProps }) => {
  return (
    <Box
      direction="row"
      border={{ color: 'light-3', size: 'small' }}
      round="6px"
      pad="xsmall"
      background={'white'}
      fontFamily="Lato, sans-serif"
      justify="center"
      align="center"
      {...boxProps}
    >
      {/* <Box> */}
      <TextInput plain value={value} onChange={(e) => setValue(e.target.value)} style={{ padding: 0, margin: 0 }} />
      {/* </Box> */}
    </Box>
  );
};

export type DataTableInputItemProps = {
  value: string | number;
  setValue: (value: string | number) => void;
  unitName?: string;
  boxProps?: BoxProps;
};
