import { BoxProps, ButtonProps } from 'grommet';
import React, { PropsWithChildren } from 'react';
import { SaveButton } from '/src/components/buttons';
import { Box, Card, CardBody, CardHeader, Text } from '/src/components/common';

export const FormCard: React.FC<FormCardProps> = (props) => {
  const {
    children,
    isLoading,
    title,
    icon,
    onSubmit,
    fill,
    hideSaveButton,
    saveButtonLabel,
    saveButtonLoadingLabel,
    saveButtonProps,
    saveButtonDisabled,
    headerChildren,
    header,
    hideRequiredText,
    ...boxProps
  } = props;

  return (
    <Box flex={fill ? undefined : 'grow'} fill={fill} {...boxProps}>
      <Card flex="shrink">
        {header ?? (
          <CardHeader title={title} icon={icon}>
            {headerChildren}
          </CardHeader>
        )}
        <CardBody
          gap="1.5rem"
          saveButton={
            !hideSaveButton && (
              <SaveButton
                type="submit"
                label={saveButtonLabel ?? ''}
                loadingLabel={saveButtonLoadingLabel ?? ''}
                isLoading={isLoading}
                onClick={onSubmit}
                disabled={saveButtonDisabled}
                {...saveButtonProps}
              />
            )
          }
        >
          {children}
          {!hideRequiredText && (
            <Text size="14px" fontFamily="Lato, sans-serif">
              * Required field
            </Text>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export type FormCardProps = PropsWithChildren &
  BoxProps & {
    isLoading?: boolean;
    title: string;
    icon: React.ReactNode;
    onSubmit: () => void;
    fill?: BoxProps['fill'];
    hideSaveButton?: boolean;
    saveButtonLabel?: ButtonProps['label'];
    saveButtonLoadingLabel?: ButtonProps['label'];
    saveButtonProps?: BoxProps;
    saveButtonDisabled?: boolean;
    headerChildren?: React.ReactNode[];
    header?: React.ReactNode;
    hideRequiredText?: boolean;
  };
