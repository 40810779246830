import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Box, FormCardSection, Input, Line, Select } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { ClientDataForm } from '/src/lib/types';

export const ClientRemittanceInformation: React.FC<ClientRemittanceInformationProps> = observer((props) => {
  /* Props */
  const { formValues, updateFormValue, formErrors, id, onSubmit } = props;

  /* Context */
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /* Callbacks */
  const renderRemittanceAddress = useCallback(
    () => (
      <FormCardSection id={id} title="Remittance Address">
        <Input
          name="remittance_address_line1"
          label="Address"
          value={formValues.remittance_address_line1}
          setValue={(value) => updateFormValue('remittance_address_line1', value)}
          error={formErrors['remittance_address_line1']}
          onSubmit={onSubmit}
          required
          disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
        />
        <Input
          name="remittance_address_line2"
          label="Address (continued)"
          value={formValues.remittance_address_line2 ?? ''}
          setValue={(value) => updateFormValue('remittance_address_line2', value)}
          error={formErrors['remittance_address_line2']}
          onSubmit={onSubmit}
          centerLabel={userStore.isFuseAdmin || (userStore.isAdminUser && userStore.isClientUser)}
          disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
        />
        <Box direction="row" gap="1rem">
          <Input
            name="remittance_address_city"
            label="City"
            value={formValues.remittance_address_city}
            setValue={(value) => updateFormValue('remittance_address_city', value)}
            error={formErrors['remittance_address_city']}
            onSubmit={onSubmit}
            required
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
          <Select
            name="remittance_address_region_id"
            label="State / Province"
            value={formValues.remittance_address_region_id}
            setValue={(value) => updateFormValue('remittance_address_region_id', value)}
            options={globalStore.regions.map((region) => ({ label: region.short_code ?? '', value: region.id }))}
            error={formErrors['remittance_address_region_id']}
            required
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
          <Input
            name="remittance_address_post_code"
            label="Postal Code"
            value={formValues.remittance_address_post_code}
            setValue={(value) => updateFormValue('remittance_address_post_code', value)}
            error={formErrors['remittance_address_post_code']}
            onSubmit={onSubmit}
            required
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
        </Box>
      </FormCardSection>
    ),
    [formValues, formErrors, userStore.isFuseAdmin, userStore.isAdminUser, userStore.isClientUser, globalStore.regions]
  );

  const renderBankingInformation = useCallback(
    () => (
      <FormCardSection title="Banking Information">
        <Box gap="1rem">
          <Input
            name="bank_name"
            label="Bank Name"
            placeholder=""
            value={formValues.bank_name ?? ''}
            setValue={(value) => updateFormValue('bank_name', value)}
            error={formErrors['bank_name']}
            onSubmit={onSubmit}
            required={formValues.remittance_method_id === 1}
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
          <Select
            name="bank_account_type_id"
            label="Bank Account Type"
            placeholder=""
            value={formValues.bank_account_type_id ?? NaN}
            setValue={(value) => updateFormValue('bank_account_type_id', value)}
            options={globalStore.bankAccountTypes.map((accountType) => ({
              label: accountType.name,
              value: accountType.id,
            }))}
            error={formErrors['bank_account_type_id']}
            required={formValues.remittance_method_id === 1}
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
          <Input
            name="routing_number"
            label="Bank Routing Number"
            placeholder=""
            value={formValues.routing_number ?? ''}
            setValue={(value) => updateFormValue('routing_number', value)}
            error={formErrors['routing_number']}
            onSubmit={onSubmit}
            flex="grow"
            required={formValues.remittance_method_id === 1}
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
          <Input
            name="account_number"
            label="Bank Account Number"
            placeholder=""
            value={formValues.account_number ?? ''}
            setValue={(value) => updateFormValue('account_number', value)}
            error={formErrors['account_number']}
            onSubmit={onSubmit}
            flex="grow"
            required={formValues.remittance_method_id === 1}
            disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
          />
        </Box>
      </FormCardSection>
    ),
    [
      formValues,
      formErrors,
      userStore.isFuseAdmin,
      userStore.isAdminUser,
      userStore.isClientUser,
      globalStore.bankAccountTypes,
    ]
  );

  /* Render */
  return (
    <FormCardSection title="Remittance Information">
      <Box margin={{ bottom: '1rem' }} gap="1rem">
        <Select
          name="remittance_method_id"
          label="Remittance Method"
          value={formValues.remittance_method_id ?? NaN}
          setValue={(value) => updateFormValue('remittance_method_id', value)}
          options={globalStore.remittanceMethods.map((method) => ({ label: method.name, value: method.id }))}
          error={formErrors['remittance_method_id']}
          required
          disabled={!userStore.isFuseAdmin && (!userStore.isAdminUser || !userStore.isClientUser)}
        />
        <Line margin="0.5rem" />
        {!formValues.remittance_method_id && <FormCardSection />}
        {formValues.remittance_method_id === 1 && renderBankingInformation()}
        {formValues.remittance_method_id === 2 && renderRemittanceAddress()}
      </Box>
    </FormCardSection>
  );
});

export type ClientRemittanceInformationProps = {
  formValues: ClientDataForm;
  updateFormValue: (key: string, value: string | number | undefined) => void; 
  formErrors: Record<string, string>;
  id?: string;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};