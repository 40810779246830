import { RadioButton, Select } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ClientService, TListClientNamesResponse } from '/src/api';
import { Box, Text } from '/src/components';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { errorMessages } from '/src/lib/errors';
import { TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { SelectOption } from '/src/lib/types';
import { filterSelectOptions, pxToRem } from '/src/utils';

export const ClientsDropdown: React.FC = observer(() => {
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();

  const [clientNames, setClientNames] = useState<TListClientNamesResponse>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const maxVisibleItems = useRef(10);

  const selectedClientId = useMemo(() => {
    return !clientStore.selectedClientId && userStore.isInternalUser ? 0 : clientStore.selectedClientId;
  }, [userStore.isInternalUser, clientStore.selectedClientId]);

  const clientOptions = useMemo(() => {
    const options = userStore.isInternalUser ? [clientStore.allClientsOption] : [];

    return filterSelectOptions<number>(searchQuery, [
      ...options,
      ...clientNames.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    ]);
  }, [userStore.isInternalUser, clientNames, searchQuery]);

  useEffect(() => {
    let ignore = false;

    ClientService.listClientNames()
      .then((clientNames) => {
        if (!ignore) {
          setClientNames(clientNames);
        }
      })
      .catch((err) => globalStore.handleApiError(err, toastMessages.listClients.error));

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (!clientStore.selectedClientId) {
      clientStore.setSelectedClientId(selectedClientId);
    }
  }, [clientStore.selectedClientId]);

  return (
    <Box justify="center" animation={{ type: 'slideLeft', duration: 500 }}>
      <Select
        name="selected_client"
        plain
        dropHeight={
          clientOptions.length < maxVisibleItems.current ? '100%' : pxToRem(60 + 34.5 * maxVisibleItems.current)
        }
        value={selectedClientId}
        labelKey="label"
        valueKey="value"
        dropProps={{ width: '20rem', round: '6px', border: { size: 'small', color: 'light-5' } }}
        valueLabel={
          <Text alignSelf="center" size="1rem">
            {clientOptions?.find((option) => option.value === selectedClientId)?.label}
          </Text>
        }
        dropAlign={{
          top: 'bottom',
          right: 'right',
        }}
        onClose={() => setSearchQuery('')}
        defaultValue={selectedClientId}
        searchPlaceholder="Filter by Company Name..."
        emptySearchMessage={errorMessages.clientNameSearchNoResult}
        options={clientOptions}
        onChange={({ value }) => clientStore.setSelectedClientId(value.value)}
        onSearch={setSearchQuery}
      >
        {(option: SelectOption<TClientId>) => (
          <Box
            direction="row"
            gap="0.5rem"
            pad={{
              vertical: 'xsmall',
              horizontal: 'small',
            }}
          >
            <RadioButton name={option.label} checked={selectedClientId === option.value} readOnly />
            <Text size="1rem" color="dark-2" truncate={true} lineHeight="1.40625rem">
              {option.label}
            </Text>
          </Box>
        )}
      </Select>
    </Box>
  );
});
