import {
  Address,
  BankAccountType,
  ClientProgram,
  EMCCommission,
  Facility,
  ReferralCommission,
  Region,
  RemittanceMethod,
  SalesCommission,
  TBankAccountTypeId,
  TCountryId,
  TRegionId,
  TRemittanceMethodId,
  TReportingPeriodTypeId,
  User,
} from '/src/lib/models';
import { DateString, Nullable } from '/src/lib/types';

export type TClientId = number;

export interface Client {
  id: TClientId;
  name: string;
  start_date: DateString;
  end_date?: Nullable<DateString>;
  first_active_reporting_quarter: DateString;
  last_active_reporting_quarter?: Nullable<DateString>;
  reporting_period_type_id: TReportingPeriodTypeId;
  contract?: Nullable<object>;
  logo?: Nullable<object>;
  website?: string;
  business_id?: string;
  bank_name?: Nullable<string>;
  routing_number?: Nullable<string>;
  account_number?: Nullable<string>;
  jumpstart_amount?: Nullable<number>;
  comments?: Nullable<string>;
  addresses: Address[];
  hq_address_line1: string;
  hq_address_line2: Nullable<string>;
  hq_address_city: string;
  hq_address_region: Region;
  hq_address_region_id: TRegionId;
  country_id: TCountryId;
  hq_address_post_code: string;
  hq_address_latitude: Nullable<string>;
  hq_address_longitude: Nullable<string>;
  remittance_address_line1: Nullable<string>;
  remittance_address_line2: Nullable<string>;
  remittance_address_city: Nullable<string>;
  remittance_address_region: Nullable<Region>;
  remittance_address_region_id: Nullable<TRegionId>;
  remittance_address_post_code: Nullable<string>;
  remittance_address_latitude: Nullable<string>;
  remittance_address_longitude: Nullable<string>;
  referral_commission_rates: ReferralCommission[];
  emc_commissions: EMCCommission[];
  users: User[];
  remittance_method: RemittanceMethod;
  remittance_method_id: TRemittanceMethodId;
  bank_account_type: BankAccountType;
  bank_account_type_id: Nullable<TBankAccountTypeId>;
  sales_commission_rates: SalesCommission[];
  facilities: Facility[];
  client_programs: ClientProgram[];
  is_demo?: boolean;
}

export interface ClientName {
  id: TClientId;
  name: string;
}

export interface ClientAddress {
  name: string;
  hq_address_line1: string;
  hq_address_line2: Nullable<string>;
  hq_address_city: string;
  hq_address_region: string;
  hq_address_country: string;
  hq_address_post_code: string;
  hq_address_latitude: Nullable<string>;
  hq_address_longitude: Nullable<string>;
}
