import dayjs from 'dayjs';
import { FormErrors, FormMetadata } from '../lib/types';

export const getFormErrors = (
  formFields: FormMetadata,
  formValues: Record<string, unknown>
): FormErrors | undefined => {
  const errors: FormErrors = {};
  Object.keys(formFields).forEach((fieldName) => {
    const { label, min, max, minValue, maxValue, nonZero, required, before, sameOrBefore, after, sameOrAfter } =
      formFields[fieldName];
    const formValue = formValues[fieldName];

    if (formValue !== undefined && formValue !== null && !Number.isNaN(formValue) && formValue !== '') {
      // Non-zero
      if (nonZero && formValue === 0) errors[fieldName] = `${label} cannot be zero`;

      // Min/max characters
      if (typeof formValue === 'string') {
        if (min && formValue.length < min) errors[fieldName] = `${label} must be more than ${min} characters`;
        if (max && formValue.length > max) errors[fieldName] = `${label} must be less than ${max} characters`;
      }

      // Min/max value
      const numericValue = typeof formValue === 'string' ? parseFloat(formValue) : Number(formValue);
      if (minValue && numericValue < minValue) errors[fieldName] = `${label} must be more than ${minValue}`;
      if (maxValue && numericValue > maxValue) errors[fieldName] = `${label} must be less than ${maxValue}`;

      // Date Comparisons
      if (before || sameOrBefore || after || sameOrAfter) {
        try {
          const dateValue = dayjs(formValue as string | number | Date);

          if (before) {
            const beforeValue = dayjs(before);
            if (dateValue.isSame(beforeValue) || dateValue.isAfter(beforeValue))
              errors[fieldName] = `${label} cannot be after first reporting period`;
          }
          if (sameOrBefore && dateValue.isAfter(dayjs(sameOrBefore))) {
            errors[fieldName] = `${label} cannot be after first reporting period`;
          }

          if (after) {
            const afterValue = dayjs(after);
            if (dateValue.isSame(afterValue) || dateValue.isBefore(afterValue))
              errors[fieldName] = `${label} cannot be before first reporting period`;
          }
          if (sameOrAfter && dateValue.isBefore(dayjs(sameOrAfter)))
            errors[fieldName] = `${label} cannot be before first reporting period`;
        } catch (_) {
          // formValue isn't a valid DateString, so let's move on
        }
      }
    } else if (required) errors[fieldName] = `${label} is required`;

    // Lat/Long validation
    if (typeof formValue === 'string') {
      if (fieldName.toLowerCase().includes('latitude') && (formValue.split('.')[1]?.length ?? 0) !== 6)
        errors[fieldName] = 'Latitude values must have 6 decimal places';
      if (fieldName.toLowerCase().includes('longitude') && (formValue.split('.')[1]?.length ?? 0) !== 6)
        errors[fieldName] = 'Longitude values must have 6 decimal places';
    }
  });

  if (Object.keys(errors).length) return errors;
};
