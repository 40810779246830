import dayjs from 'dayjs';
import {
  Client,
  ClientReportingPeriod,
  Equipment,
  EquipmentUsage,
  Facility,
  IncentiveStatementFacilityValue,
  Model,
  ModelName,
  User,
} from '/src/lib/models';
import { DateString, FloatString, ModelType, Nullable, SelectOption, SelectOptions } from '/src/lib/types';

/**
 * Capitalize
 * @param input
 * @returns Capitalized string
 */
export const capitalize = (input: string): string => {
  const words = input.split(' ');
  if (words.length === 1) return input[0].toUpperCase() + input.slice(1);
  else {
    return words.map((word) => word[0].toUpperCase() + word.slice(1)).join(' ');
  }
};

/**
 * Snake to Camel Case
 * @param snakeCase
 * @returns Camel case string
 */
export const snakeToCamelCase = (snakeCase: string) => {
  const words = snakeCase.split('_');
  if (!words.length) return '';
  if (words.length === 1) return words[0];
  let camelCase = words[0];
  for (let i = 1; i < words.length; i++) {
    camelCase += capitalize(words[i]);
  }
  return camelCase;
};

/**
 * Convert To Snake Case
 * @param input
 * @returns Snake case string
 */
export const toSnakeCase = (input: string) => input.toLowerCase().replace(/ /g, '_');

/**
 * Camel To Snake Case
 * @param camelCase
 * @returns Snake case string
 */
export const camelToSnakeCase = (camelCase: string) => toSnakeCase(fromCamelCase(camelCase));

/**
 * Format Number
 * @param input
 * @param addCommas
 * @param isCurrency
 * @returns Formatted number string
 */
export const formatNumber = (
  input: number | string | FloatString,
  addCommas = true,
  isCurrency = false,
  isFinancial = false,
  toFixed?: number
): string => {
  if (!input) return '';
  if (typeof input === 'number' && Number.isNaN(input)) return '0';

  input = typeof input === 'number' ? (toFixed ? input.toFixed(toFixed) : input.toString()) : input;
  let result = input;

  if (addCommas) {
    const splitDecimal = input.split('.');
    const inputString = splitDecimal[0];

    if (inputString.length > 3) {
      let loopString = inputString;
      const outputStrings = [];

      for (let i = 0; i < inputString.length; i += 1) {
        if (i !== 0 && i % 3 === 0) {
          const sliceIndex = inputString.length - i;

          const stringAfter = loopString.slice(sliceIndex);
          const stringBefore = loopString.slice(0, sliceIndex);
          outputStrings.push(stringAfter);
          loopString = stringBefore;

          if (sliceIndex <= 3) outputStrings.push(stringBefore);
        }
      }

      result = outputStrings.reverse().join(',');
    } else {
      result = inputString;
    }

    if (splitDecimal?.[1]) result = `${result}.${splitDecimal[1]}`;
    else if (toFixed) {
      result += '.';
      for (let i = 0; i < toFixed; i += 1) {
        result += '0';
      }
    }
  }

  if (isFinancial && result[0] === '-') {
    result = `(${result.slice(result[1] === ',' ? 2 : 1)})`;
  } else if (isCurrency) result = `$${result}`;

  return result;
};

export const isPxSize = (size: string) => size?.toLowerCase().includes('px');

export const pxToRem = (pxSize: string | number) =>
  typeof pxSize === 'string'
    ? isPxSize(pxSize)
      ? `${parseFloat(pxSize.slice(0, -2)) / 16}rem`
      : pxSize
    : `${pxSize / 16}rem`;

export const pxToPt = (pxSize: string | number) =>
  typeof pxSize === 'string'
    ? isPxSize(pxSize)
      ? `${parseFloat(pxSize.slice(0, -2)) * 0.75}pt`
      : pxSize
    : `${pxSize * 0.75}pt`;

export const getInitials = (name: string, separator = ' ') => {
  if (!name) return '';
  const words = name.split(separator);
  if (words.length === 1) return words[0][0].toUpperCase();
  else return `${words[0][0].toUpperCase()}${words[words.length - 1][0].toUpperCase()}`;
};

export const scramble = (type: 'letters' | 'numbers' = 'numbers', outputLength = 9) => {
  let randomStr = '';
  for (let i = 0; i < outputLength; i++) {
    if (type === 'numbers') {
      randomStr += (Math.random() * 9).toString();
    }
  }
  return randomStr;
};

/**
 * Quarter from Date String
 */
export const getQuarterFromDateString = (dateString: DateString): string => {
  if (!dateString) return '';
  const input = new Date(dateString);
  const quarters: Record<number, string> = {
    0: 'Q1',
    1: 'Q1',
    2: 'Q1',
    3: 'Q2',
    4: 'Q2',
    5: 'Q2',
    6: 'Q3',
    7: 'Q3',
    8: 'Q3',
    9: 'Q4',
    10: 'Q4',
    11: 'Q4',
  };
  return `${quarters[input.getUTCMonth()]} ${input.getUTCFullYear()}`;
};

/**
 * Quarter from Date Object
 */
export const getQuarterFromDate = (input: Date): string => {
  if (!input) return '';
  const quarters: Record<number, string> = {
    0: 'Q1',
    1: 'Q1',
    2: 'Q1',
    3: 'Q2',
    4: 'Q2',
    5: 'Q2',
    6: 'Q3',
    7: 'Q3',
    8: 'Q3',
    9: 'Q4',
    10: 'Q4',
    11: 'Q4',
  };
  return `${quarters[input.getUTCMonth()]} ${input.getUTCFullYear()}`;
};

/**
 * Date String from Quarter
 */
export const getDateStringFromQuarter = (input: string): DateString => {
  const months: Record<string, string> = {
    Q1: '01',
    Q2: '04',
    Q3: '07',
    Q4: '10',
  };
  const [quarter, year] = input.split(' ');
  return `${year}-${months[quarter]}-01`;
};

/**
 * Quarter Date Range
 */
export const getQuarterRange = (input: DateString | Date) => {
  return `${dayjs(input).format('MMMM DD, YYYY')} - ${dayjs(input)
    .add(2, 'months')
    .endOf('month')
    .format('MMMM DD, YYYY')}`;
};

/**
 * Year from Date String
 */
export const getYearFromDateString = (input: DateString) => dayjs(input).year().toString();

/**
 * Year from Date
 */
export const getYearFromDate = (input: Date) => dayjs(input).year().toString();

/**
 * Date String from Year
 */
export const getDateStringFromYear = (input: DateString | Date) =>
  dayjs(input).set('month', 0).set('date', 1).format('YYYY-MM-DD');

/**
 * Year Date Range
 */
export const getYearRange = (input: DateString | Date) => {
  return `${dayjs(input).set('month', 0).set('date', 1).format('MMMM DD, YYYY')} - ${dayjs(input)
    .set('month', 11)
    .set('date', 31)
    .format('MMMM DD, YYYY')}`;
};

/**
 * Formats DateString as M/D/YYYY
 */
export const getFormattedDateString = (input: DateString, isCanada: boolean): string => {
  if (!input) return '';

  const date = dayjs(input);
  
  if (!date.isValid()) {
    console.warn(`Invalid date string: ${input}`); 
    return ''; 
  }
  return isCanada ? dayjs(date).format('YYYY-MM-DD') : dayjs(date).format('M/D/YYYY');
};

/**
 * Period from Date String
 */
  export const getPeriodFromDateString = (input: DateString, isYearly: boolean): string => {
    if (!input) return '';

    const date = dayjs(input);
  
    if (!date.isValid()) {
      console.warn(`Invalid date: ${input}`); 
      return ''; 
    }
    return isYearly ? getYearFromDateString(input) : getQuarterFromDateString(input);
  };

/**
 * Period from Date
 */
export const getPeriodFromDate = (input: Date, isYearly: boolean) =>
  isYearly ? getYearFromDate(input) : getQuarterFromDate(input);

/**
 * Period Date Range
 */
export const getPeriodRange = (input: DateString | Date, isYearly: boolean) =>
  isYearly ? getYearRange(input) : getQuarterRange(input);

export const filterStringOptions = (filter: string, options: string[]) => {
  const exp = new RegExp(filter.toLowerCase().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
  const filtered = options.filter((option) => exp.test(option.toLowerCase()));
  return filtered;
};

export const filterSelectOptions = <T>(filter: string, options: SelectOption<T>[]) => {
  const exp = new RegExp(filter.toLowerCase().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
  const filtered = options.filter(({ label }) => exp.test(label.toLowerCase()));
  return filtered;
};

export const getQueryParams = (queryString: string) => {
  const queryParams: Record<string, string> = {};
  queryString
    .slice(1)
    .split('&')
    .map((kv) => kv.split('='))
    .forEach((v) => (queryParams[v[0]] = v[1]));
  return queryParams;
};

export const getFacilityLabel = (facility?: Facility | IncentiveStatementFacilityValue, full?: boolean) => {
  if (facility) {
    const labelData: Record<string, Nullable<string>> = {};
    if ((facility as Facility).address_line1) {
      const f = facility as Facility;
      labelData.name = f.name;
      labelData.address_line1 = f.address_line1;
      labelData.address_line2 = f.address_line2;
      labelData.address_city = f.address_city;
      labelData.address_region_short_code = f.address_region?.short_code ?? null;
      labelData.address_post_code = f.address_post_code;
    } else {
      const f = facility as IncentiveStatementFacilityValue;
      labelData.name = null;
      labelData.address_line1 = f.facility_address_line1;
      labelData.address_line2 = f.facility_address_line2;
      labelData.address_city = f.facility_address_city;
      labelData.address_region_short_code = f.facility_address_region?.short_code;
      labelData.address_post_code = f.facility_address_post_code;
    }

    let label =
      labelData.name ?? `${labelData.address_line1}${labelData.address_line2 ? ` ${labelData.address_line2}` : ''}`;
    if (full) {
      label += `, ${labelData.address_city}, ${labelData.address_region_short_code} ${labelData.address_post_code}`;
    }

    return label;
  }

  return '';
};

export const standardizeFacilityAddress = (addressLine: string) =>
  addressLine
    .replace(/\./g, '')
    .replace(/North|north/g, 'N')
    .replace(/South|south/g, 'S')
    .replace(/East|east/g, 'E')
    .replace(/West|west/g, 'W')
    .replace(/Avenue|avenue/g, 'Ave')
    .replace(/Street|street/g, 'St')
    .replace(/Road|road/g, 'Rd')
    .replace(/Boulevard|boulevard/g, 'Blvd')
    .replace(/Parkway|parkway/g, 'Pkwy');

export const getSearchResultUrlAndLabel = (result: Client | Facility | Equipment | User, selectedEntity: ModelType) => {
  let url = '';
  let label = '';
  switch (selectedEntity) {
    case ModelType.Clients:
      url = `/clients/${result.id}`;
      label = (result as Client).name;
      break;
    case ModelType.Facilities:
      url = `/clients/${(result as Facility).client?.id}/facilities/${result.id}`;
      label = getFacilityLabel(result as Facility);
      break;
    case ModelType.Equipment:
      url = `/clients/${(result as Equipment).client?.id}/equipment/${result.id}`;
      label = `${(result as Equipment).unit_number} (${(result as Equipment).model_year} ${
        (result as Equipment).manufacturer
      }) ${(result as Equipment).model_number}`;
      break;
    case ModelType.Users:
      url = `/users/${result.id}`;
      label = (result as User).name;
      break;
    default:
      break;
  }
  return { url, label };
};

export const getModelUrl = (model: Nullable<Model>, modelName?: ModelName) => {
  if (!model) return '';
  let data = model;
  switch (modelName) {
    case ModelName.Client:
      data = data as Client;
      return `/clients/${data.id}`;
    case ModelName.Facility:
      data = data as Facility;
      return `/clients/${data.client_id}/facilities/${data.id}`;
    case ModelName.Equipment:
      data = data as Equipment;
      return `/clients/${data.client_id}/equipment/${data.id}`;
    case ModelName.EquipmentUsage:
      data = data as EquipmentUsage;
      return `/clients/${data.client_id}/equipment/${data.equipment_id}/usages/${data.id}`;
    case ModelName.User:
      data = data as User;
      return `/users/${data.id}`;
    default:
      return '';
  }
};

export const getModelLabel = (model: Nullable<Model>, modelName?: ModelName) => {
  switch (modelName) {
    case ModelName.Client:
      return (model as Client)?.name ?? '';
    case ModelName.Facility:
      return `${(model as Facility)?.address_line1 ?? ''}`;
    case ModelName.Equipment:
      return `${(model as Equipment)?.manufacturer ?? ''}: ${(model as Equipment)?.serial_number ?? ''}`;
    case ModelName.EquipmentUsage:
      return (model as EquipmentUsage)?.start_reporting_quarter
        ? getQuarterFromDateString((model as EquipmentUsage).start_reporting_quarter)
        : '';
    case ModelName.User:
      return (model as User)?.name ?? '';
  }
};

/**
 * Converts a camelCase string to a normal string.
 * @param {string} camelCase
 * @returns String
 */
export const fromCamelCase = (camelCase: string) => {
  if (!camelCase.length) return '';
  let result = camelCase[0].toUpperCase();
  for (let i = 1; i < camelCase.length; i++) {
    result += isUpperCase(camelCase[i]) ? ` ${camelCase[i]}` : camelCase[i];
  }
  return result;
};

/**
 * Returns `true` if a letter is in upper case.
 * @param {string} letter
 * @returns boolean
 */
export const isUpperCase = (letter: string) =>
  letter === 'A' ||
  letter === 'B' ||
  letter === 'C' ||
  letter === 'D' ||
  letter === 'E' ||
  letter === 'F' ||
  letter === 'G' ||
  letter === 'H' ||
  letter === 'I' ||
  letter === 'J' ||
  letter === 'K' ||
  letter === 'L' ||
  letter === 'M' ||
  letter === 'N' ||
  letter === 'O' ||
  letter === 'P' ||
  letter === 'Q' ||
  letter === 'R' ||
  letter === 'S' ||
  letter === 'T' ||
  letter === 'U' ||
  letter === 'V' ||
  letter === 'W' ||
  letter === 'X' ||
  letter === 'Y' ||
  letter === 'Z';

/**
 * Converts a string to JSON, returning `undefined` instead of throwing an error if string is not valid JSON.
 * @param {string} input
 * @returns JSON object
 */
export const toJSON = (input: string) => {
  try {
    return JSON.parse(input);
  } catch (_) {
    return;
  }
};

/**
 * Converts JSON to a string, returning `undefined` instead of throwing an error if string is not valid JSON.
 * @param {string} input
 * @returns JSON object
 */
export const fromJSON = <T>(input: T) => {
  try {
    return JSON.stringify(input);
  } catch (_) {
    return;
  }
};

export const getPageTitle = (base: string, ...titles: string[]) =>
  titles.length ? [base, ...titles].join(' - ') : base;

export const sortPeriods = (reportingPeriods: ClientReportingPeriod[], direction: 'asc' | 'desc' = 'desc') =>
  reportingPeriods.sort((a, b) =>
    a.start_reporting_quarter > b.start_reporting_quarter
      ? direction === 'asc'
        ? 1
        : -1
      : direction === 'asc'
        ? -1
        : 1
  );

export const sortPeriodOptions = (
  reportingPeriodOptions: SelectOptions<DateString>,
  direction: 'asc' | 'desc' = 'desc'
) =>
  reportingPeriodOptions.sort((a, b) =>
    a.value > b.value ? (direction === 'asc' ? 1 : -1) : direction === 'asc' ? -1 : 1
  );
