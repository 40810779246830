export type ApiRequestOptions = {
  readonly method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH';
  readonly url: string;
  readonly path?: Record<string, string | number | boolean>; 
  readonly cookies?: Record<string, string>; 
  readonly headers?: Record<string, string>; 
  readonly query?: Record<string, string | number | boolean | string[]>; 
  readonly formData?: Record<string, string | Blob>; 
  readonly body?: unknown; 
  readonly mediaType?: string;
  readonly responseHeader?: string;
  readonly errors?: Record<number, string>;
};