import { ResponsiveContext, Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet';
import { useContext, useMemo, useRef } from 'react';
import { Box, BoxProps, Text } from '/src/components';
import { IncentiveStatement, IncentiveStatementFacilityValue, IncentiveStatementProgramTotal } from '/src/lib/models';
import { IncentiveStatementFacilityValues } from '/src/lib/types';
import { formatNumber, getFacilityLabel, getIsMobile, pxToPt } from '/src/utils';

export const IncentiveStatementDataTable: React.FC<IncentiveStatementDataTableProps> = (props) => {
  const { incentiveStatement, isSummary, ...boxProps } = props;

  const screenSize = useContext(ResponsiveContext);
  const isMobile = getIsMobile(screenSize);

  const tableHeaders = useRef([
    'Program',
    'Credits',
    'Credit Price',
    'Total Value',
    'REC Cost',
    'Broker Cost',
    'FuSE Cost',
    'Net Incentive',
  ]);

  // Cells
  const getBackground = (rowIndex: number) => (rowIndex % 2 ? 'white' : 'light-6');
  const verticalPad = pxToPt('4px');
  const horizontalPad = pxToPt('4px');
  const cellBorder: BoxProps['border'] = { size: '0' };
  const totalsCellBorder: BoxProps['border'] = { side: 'top', size: 'xsmall', color: 'text' };
  const horizontalMargin = pxToPt('18px');
  const facilityIndent = pxToPt('12px');
  const nameCellSizePct = 25;
  const currencyCellSizePct = 11;

  // Text
  const lineHeight = pxToPt('18px');
  const headerSize = pxToPt('11px');
  const programValueSize = pxToPt('10px');
  const facilityValueSize = pxToPt('9px');
  const totalsSize = pxToPt('11px');
  const grandTotalSize = pxToPt('13px');

  /* Memos */
  const programFacilityValues = useMemo(() => {
    const facilityValues: IncentiveStatementFacilityValues = {};
    incentiveStatement.incentive_statement_facility_values?.forEach((facilityValue) => {
      facilityValues[facilityValue.program_id] ??= [];
      facilityValues[facilityValue.program_id].push(facilityValue);
    });
    return facilityValues;
  }, [incentiveStatement]);

  /* Render */
  // const renderValue = (
  //   value: string,
  //   isProgramLine?: boolean,
  //   isTotalsLine?: boolean,
  //   isCurrency?: boolean,
  //   isBold?: boolean
  // ) =>
  //   isCurrency ? (
  //     <Box direction="row" justify="between" gap={pxToPt('3px')}>
  //       <Text
  //         size={isProgramLine ? programValueSize : isTotalsLine ? totalsSize : facilityValueSize}
  //         lineHeight={lineHeight}
  //         weight={(isProgramLine || isTotalsLine) && isBold !== false ? 700 : undefined}
  //       >
  //         $
  //       </Text>
  //       <Text
  //         size={isProgramLine ? programValueSize : isTotalsLine ? totalsSize : facilityValueSize}
  //         lineHeight={lineHeight}
  //         weight={(isProgramLine || isTotalsLine) && isBold !== false ? 700 : undefined}
  //       >
  //         {}
  //         {value && !!parseFloat(value) ? formatNumber(value, true, true, true) : '-'}
  //       </Text>
  //     </Box>
  //   ) : (
  //     <Box>
  //       <Text
  //         size={isProgramLine ? programValueSize : isTotalsLine ? totalsSize : facilityValueSize}
  //         lineHeight={lineHeight}
  //         weight={(isProgramLine || isTotalsLine) && isBold !== false ? 700 : undefined}
  //       >
  //         {value}
  //       </Text>
  //     </Box>
  //   );

  const renderHeaders = (headers: string[]) => (
    <TableRow>
      {headers.map((header, i) => (
        <TableCell
          key={header}
          scope="col"
          border={cellBorder}
          align={!isMobile && i > 1 ? 'center' : undefined}
          pad={{
            bottom: verticalPad,
            left: !i ? horizontalPad : undefined,
            right: i === tableHeaders.current.length - 1 ? horizontalPad : undefined,
          }}
        >
          <Text size={headerSize} lineHeight={lineHeight} weight={700}>
            {header}
          </Text>
        </TableCell>
      ))}
    </TableRow>
  );

  const renderDataLine = (
    data: {
      programTotal?: IncentiveStatementProgramTotal;
      facilityValue?: IncentiveStatementFacilityValue;
      incentiveStatement?: IncentiveStatement;
    },
    rowIndex?: number
  ) => {
    const { programTotal, facilityValue, incentiveStatement } = data;
    if (!facilityValue && !incentiveStatement && !programTotal) return;

    const textSize = programTotal ? programValueSize : facilityValue ? facilityValueSize : totalsSize;
    const border = incentiveStatement ? totalsCellBorder : cellBorder;
    const textWeight = (programTotal && !isSummary) || incentiveStatement ? 700 : undefined;

    const values: Record<string, string> = {};

    values.title = programTotal
      ? programTotal.program.name
      : facilityValue
        ? getFacilityLabel(facilityValue)
        : 'Totals';

    const credits =
      programTotal?.total_credits_generated ??
      facilityValue?.credits_generated ??
      incentiveStatement?.total_credits_generated ??
      '0';
    values.credits = !Number.isNaN(parseFloat(credits)) ? formatNumber(credits, true, false, false, 2) : '-';

    const credit_price = programTotal?.final_sale_price ?? facilityValue?.final_sale_price ?? '';
    values.credit_price = !Number.isNaN(parseFloat(credit_price))
      ? formatNumber(credit_price, true, false, false, 2)
      : '-';

    const total_value =
      programTotal?.total_total_value ?? facilityValue?.total_value ?? incentiveStatement?.total_total_value ?? '0';
    values.total_value = !Number.isNaN(parseFloat(total_value))
      ? formatNumber(total_value, true, false, false, 2)
      : '-';

    const rec_cost =
      programTotal?.total_rec_cost ?? facilityValue?.rec_cost ?? incentiveStatement?.total_rec_cost ?? '0';
    values.rec_cost = !Number.isNaN(parseFloat(rec_cost)) ? `(${formatNumber(rec_cost, true, false, false, 2)})` : '-';

    const broker_cost =
      programTotal?.total_broker_fee ?? facilityValue?.broker_fee ?? incentiveStatement?.total_broker_fee ?? '0';
    values.broker_cost = !Number.isNaN(parseFloat(broker_cost))
      ? `(${formatNumber(broker_cost, true, false, false, 2)})`
      : '-';

    const fuse_cost =
      programTotal?.total_commission_earned ??
      facilityValue?.commission_earned ??
      incentiveStatement?.total_commission_earned ??
      '0';
    values.fuse_cost = !Number.isNaN(parseFloat(fuse_cost))
      ? `(${formatNumber(fuse_cost, true, false, false, 2)})`
      : '-';

    const net_incentive =
      programTotal?.total_client_revenue ??
      facilityValue?.client_revenue ??
      incentiveStatement?.total_client_revenue ??
      '0';
    values.net_incentive = !Number.isNaN(parseFloat(net_incentive))
      ? formatNumber(net_incentive, true, false, false, 2)
      : '-';

    return (
      <TableRow key={rowIndex}>
        <TableCell
          scope="row"
          border={border}
          size={`${nameCellSizePct}%`}
          pad={{
            vertical: verticalPad,
            right: horizontalMargin,
            left: facilityValue ? facilityIndent : horizontalPad,
          }}
          background={rowIndex !== undefined ? getBackground(rowIndex) : undefined}
        >
          <Box>
            <Text size={textSize} lineHeight={lineHeight} weight={programTotal || incentiveStatement ? 700 : undefined}>
              {values.title}
            </Text>
          </Box>
        </TableCell>
        <TableCell
          scope="row"
          border={border}
          pad={{ vertical: verticalPad, right: horizontalMargin }}
          background={rowIndex !== undefined ? getBackground(rowIndex) : undefined}
        >
          <Box>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              {values.credits}
            </Text>
          </Box>
        </TableCell>
        <TableCell
          scope="row"
          border={border}
          size={`${currencyCellSizePct}%`}
          align={incentiveStatement ? 'center' : undefined}
          pad={{ vertical: verticalPad, right: horizontalMargin }}
          background={rowIndex !== undefined ? getBackground(rowIndex) : undefined}
        >
          {values.credit_price === '-' && (
            <Box>
              <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
                N/A
              </Text>
            </Box>
          )}
          {values.credit_price !== '-' && (
            <Box direction="row" justify="between" gap={pxToPt('3px')}>
              <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
                $
              </Text>
              <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
                {values.credit_price}
              </Text>
            </Box>
          )}
        </TableCell>
        <TableCell
          scope="row"
          border={border}
          size={`${currencyCellSizePct}%`}
          pad={{ vertical: verticalPad, right: horizontalMargin }}
          background={rowIndex !== undefined ? getBackground(rowIndex) : undefined}
        >
          <Box direction="row" justify="between" gap={pxToPt('3px')}>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              $
            </Text>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              {values.total_value}
            </Text>
          </Box>
        </TableCell>
        <TableCell
          scope="row"
          border={border}
          size={`${currencyCellSizePct}%`}
          pad={{ vertical: verticalPad, right: horizontalMargin }}
          background={rowIndex !== undefined ? getBackground(rowIndex) : undefined}
        >
          <Box direction="row" justify="between" gap={pxToPt('3px')}>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              $
            </Text>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              {values.rec_cost}
            </Text>
          </Box>
        </TableCell>
        <TableCell
          scope="row"
          border={border}
          size={`${currencyCellSizePct}%`}
          pad={{ vertical: verticalPad, right: horizontalMargin }}
          background={rowIndex !== undefined ? getBackground(rowIndex) : undefined}
        >
          <Box direction="row" justify="between" gap={pxToPt('3px')}>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              $
            </Text>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              {values.broker_cost}
            </Text>
          </Box>
        </TableCell>
        <TableCell
          scope="row"
          border={border}
          size={`${currencyCellSizePct}%`}
          pad={{ vertical: verticalPad, right: horizontalMargin }}
          background={rowIndex !== undefined ? getBackground(rowIndex) : undefined}
        >
          <Box direction="row" justify="between" gap={pxToPt('3px')}>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              $
            </Text>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              {values.fuse_cost}
            </Text>
          </Box>
        </TableCell>
        <TableCell
          scope="row"
          border={border}
          size={`${currencyCellSizePct}%`}
          pad={{ vertical: verticalPad, right: horizontalPad }}
          background={rowIndex !== undefined ? getBackground(rowIndex) : undefined}
        >
          <Box direction="row" justify="between" gap={pxToPt('3px')}>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              $
            </Text>
            <Text size={textSize} lineHeight={lineHeight} weight={textWeight}>
              {values.net_incentive}
            </Text>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const renderCostRecoup = (incentiveStatement: IncentiveStatement) => (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell
            scope="row"
            size={`${100 - currencyCellSizePct}%`}
            pad={{ vertical: verticalPad, right: horizontalMargin }}
          >
            <Box direction="row" justify="end" gap={pxToPt('3px')}>
              <Text size={totalsSize} lineHeight={lineHeight}>
                Cost Recoup
              </Text>
              <Text size={totalsSize} lineHeight={lineHeight}>
                <i>(If Applicable)</i>
              </Text>
            </Box>
          </TableCell>
          <TableCell scope="row" size={`${currencyCellSizePct}%`} pad={{ vertical: verticalPad, right: horizontalPad }}>
            {incentiveStatement.cost_recoup ? (
              <Box direction="row" justify="between" gap={pxToPt('3px')}>
                <Text size={totalsSize} lineHeight={lineHeight}>
                  $
                </Text>
                <Text size={totalsSize} lineHeight={lineHeight}>
                  {formatNumber(incentiveStatement.cost_recoup, true, false, false, 2)}
                </Text>
              </Box>
            ) : (
              <Box align="center">
                <Text size={totalsSize} lineHeight={lineHeight}>
                  N/A
                </Text>
              </Box>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const renderGrandTotal = (incentiveStatement: IncentiveStatement) => (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell
            scope="row"
            size={`${100 - currencyCellSizePct}%`}
            pad={{ vertical: verticalPad, right: horizontalMargin }}
          >
            <Box align="end">
              <Text size={grandTotalSize} lineHeight={lineHeight} color="brand" weight={700}>
                Grand Total
              </Text>
            </Box>
          </TableCell>
          <TableCell
            scope="row"
            size={`${`${currencyCellSizePct}%`}%`}
            pad={{ vertical: verticalPad, right: horizontalPad }}
          >
            <Box direction="row" justify="between" gap={pxToPt('3px')}>
              <Text size={grandTotalSize} lineHeight={lineHeight} color="brand" weight={700}>
                $
              </Text>
              <Text size={grandTotalSize} lineHeight={lineHeight} color="brand" weight={700}>
                {formatNumber(incentiveStatement.grand_total, true, false, false, 2)}
              </Text>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <Box fill="horizontal" {...boxProps}>
      <Table>
        <TableHeader>{renderHeaders(tableHeaders.current)}</TableHeader>
        <TableBody>
          {incentiveStatement.program_totals.map((programTotal, pi) => (
            <>
              {renderDataLine({ programTotal }, pi)}
              {!isSummary &&
                programFacilityValues[programTotal.program_id].map((facilityValue, fi) =>
                  renderDataLine({ facilityValue }, pi + fi + 1)
                )}
            </>
          ))}
          <TableRow>
            <TableCell pad={{ vertical: verticalPad }} height={programValueSize} />
          </TableRow>
          {renderDataLine({ incentiveStatement })}
        </TableBody>
      </Table>
      {renderCostRecoup(incentiveStatement)}
      {renderGrandTotal(incentiveStatement)}
    </Box>
  );
};

export type IncentiveStatementDataTableProps = BoxProps & {
  incentiveStatement: IncentiveStatement;
  isSummary?: boolean;
};
