import { ResponsiveContext } from 'grommet';
import { MapLocation } from 'grommet-icons';
import { useContext, useEffect } from 'react';
import { DesktopLayout } from 'src/layouts';
import { config } from '../config';
import { getIsMobile, getPageTitle } from '../utils';
import { Box, Card, CardBody, CardHeader, Text } from '/src/components';

const PageNotFound: React.FC = () => {
  const screenSize = useContext(ResponsiveContext);
  const isMobile = getIsMobile(screenSize);

  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Page Not Found');
  }, []);

  return (
    <DesktopLayout>
      <Box flex="grow" justify="center" align="center">
        <Card flex="shrink" width={!isMobile ? '35%' : '80%'} margin={{ bottom: 'xlarge' }}>
          <CardHeader title="Page Not Found" icon={<MapLocation color="brand" />}></CardHeader>
          <CardBody>
            <Box pad={{ vertical: 'xsmall' }}>
              <Text fontFamily="Lato, sans-serif">
                We couldn't find anything at this address. Check the URL and try again.
              </Text>
            </Box>
          </CardBody>
        </Card>
      </Box>
    </DesktopLayout>
  );
};

export default PageNotFound;
