import type { ApiRequestOptions } from '/src/api/core/ApiRequestOptions';
import type { ApiResult } from '/src/api/core/ApiResult';

export type ApiErrorItem = {
  rule: string;
  field: string;
  message: string;
  args: Record<string, string | number>;
};

export class ApiError extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  public readonly body: unknown;
  public readonly request: ApiRequestOptions;

  constructor(request: ApiRequestOptions, response: ApiResult, message: string) {
    super(message);

    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.body;
    this.request = request;
  }
}
