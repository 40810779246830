import { Button, Header, Nav } from 'grommet';
import { CircleQuestion } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiError } from '/src/api';
import {
  AnchorLink,
  Box,
  ClientsDropdown,
  FuseLogo,
  Link,
  ModelAuditLogDropdown,
  SearchDropdown,
  Text,
  UserProfileMenu,
} from '/src/components';
import { useClientStore, useGlobalStore, useModelAuditLogStore, useUserStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';
import { AuditorType, ModelAuditLogState } from '/src/lib/types';
import { pxToRem } from '/src/utils';
import { config } from '/src/config';

export const HeaderNav: React.FC<HeaderNavProps> = observer((props) => {
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();
  const modelAuditLogStore = useModelAuditLogStore();
  const location = useLocation();
  const navigate = useNavigate();

  const [hoverItem, setHoverItem] = useState<string>();

  const clientId = userStore.isExternalUser ? clientStore.activeClientId : clientStore.selectedClientId;

  const fetchModelAuditLogs = async () => {
    try {
      await modelAuditLogStore.fetchModelAuditLogs({
        action_taken_by: AuditorType.ExternalUser,
        model_audit_log_state_id: ModelAuditLogState.Unresolved,
      });
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchAuditLog.error);
    }
  };

  useEffect(() => {
    fetchModelAuditLogs();
  }, [location]);

  const selectedItem = useMemo(() => {
    if (location.pathname.includes('/clients')) return 'Clients';
    else if (location.pathname.includes('/users')) return 'Users';
    else if (location.pathname === '/') return 'Dashboard';
  }, [location.pathname]);

  const renderLinkItem = useCallback(
    (url: string, label: string, leftBorderSize?: string) => {
      const isSelected = selectedItem === label;
      const isHover = hoverItem === label;
      return (
        <Box
          key={label}
          // animation={{ type: 'fadeIn', duration: 300 }}
          direction="row"
          border={{ side: 'left', size: leftBorderSize || '1px', color: 'light-4' }}
          pad={{ horizontal: '0.875rem', vertical: '1rem' }}
          width="110px"
          focusIndicator={false}
          justify="center"
          align="center"
          cursor="pointer"
          style={{
            boxShadow: isSelected || isHover ? 'rgba(24, 100, 158, 0.3) 0px -24px 24px -24px inset' : '',
          }}
          transitionProperty="color, box-shadow"
          transitionDuration="0.15s, 0.15s"
          transitionTimingFunction="ease, ease"
          onClick={() => navigate(url)}
          onMouseOver={() => setHoverItem(label)}
          onMouseOut={() => setHoverItem(undefined)}
        >
          <Box
            transitionProperty="padding-bottom"
            transitionDuration="0.15s"
            transitionTimingFunction="ease"
            pad={{ bottom: isHover ? '3px' : undefined }}
          >
            <Link to={url}>
              <Text
                size={pxToRem(18)}
                lineHeight={pxToRem(18)}
                weight={300}
                color={!isSelected || isHover ? 'accent-2' : undefined}
              >
                {label}
              </Text>
            </Link>
          </Box>
        </Box>
      );
    },
    [selectedItem, hoverItem, setHoverItem]
  );

  const linkItems = useMemo<Record<string, boolean | string>[]>(
    () => [
      // All users
      { shouldShow: true, url: '/', label: 'Dashboard' },

      // Internal users
      { shouldShow: userStore.isInternalUser, url: '/clients', label: 'Clients' },
      { shouldShow: userStore.isInternalUser, url: '/users', label: 'Users' },
      { shouldShow: userStore.isInternalUser, url: '/api-data', label: 'API Data' },

      // External users
      { shouldShow: userStore.isClientUser && !!clientId, url: `/clients/${clientId}`, label: 'Company' },
      {
        shouldShow: !userStore.isInternalUser && !!clientId,
        url: `/clients/${clientId}/facilities`,
        label: 'Facilities',
      },
      { shouldShow: userStore.isClientAdmin && !!clientId, url: `/clients/${clientId}/users`, label: 'Users' },
      { shouldShow: userStore.isClientUser && !!clientId, url: `/clients/${clientId}/statements`, label: 'Statements' },
    ],
    [userStore.isInternalUser, userStore.isClientUser, clientId]
  );

  const HelpButton = () => (
    <Box width="20px" height="20px" alignSelf="center">
      <Link to={config.urls.fuseSupportUrl} target="_blank">
        <Button plain icon={<CircleQuestion size="20" />} tip="View support page" />
      </Link>
    </Box>
  );

  return (
    <Header
      gridArea={props.gridArea}
      direction="row"
      animation={!globalStore.didAnimateHeader ? { type: 'slideDown', duration: 300, size: 'large' } : undefined}
      onAnimationEnd={() => globalStore.setDidAnimateHeader(true)}
    >
      {userStore.user && (
        <Nav
          animation={!globalStore.didAnimateHeader ? { type: 'fadeIn', duration: 300 } : undefined}
          direction="row"
          background="white"
          flex="grow"
          pad={{ horizontal: '7.2rem' }}
          border={{ color: 'brand', side: 'bottom', size: '6px' }}
        >
          <Box direction="row" align="center" gap="small">
          <AnchorLink to="/" icon={<FuseLogo height="40px" />} alignSelf="center" />
          {process.env.NODE_ENV === 'development' && (
              <Text color="red" weight="bold" size="large">
                DEV
              </Text>
            )}
          </Box>
          <Box direction="row" border={{ side: 'right', size: '1px', color: 'light-4' }}>
            {linkItems
              .filter(({ shouldShow }) => shouldShow)
              .map(({ url, label }) => renderLinkItem(url as string, label as string))}
          </Box>
          <Box flex="grow" />
          {location.pathname === '/' && !userStore.isClientUser && !userStore.isFacilityUser && <ClientsDropdown />}
          {location.pathname !== '/search' && <SearchDropdown />}
          {userStore.isFuseAdmin && <ModelAuditLogDropdown />}
          <HelpButton />
          <UserProfileMenu margin={{ right: '0.875rem' }} />
        </Nav>
      )}
    </Header>
  );
});

export type HeaderNavProps = {
  gridArea?: string;
};