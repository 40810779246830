import { ReactNode, createContext, useContext } from 'react';
import RootStore from '/src/context/stores/rootStore';

const RootContext = createContext<RootStore | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function useRootStore() {
  const context = useContext(RootContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootContextProvider');
  }
  return context;
}

export function useClientStore() {
  const { clientStore } = useRootStore();
  if (clientStore === undefined) {
    throw new Error('useClientStore must be used within RootContextProvider');
  }
  return clientStore;
}

export function useDashboardStore() {
  const { dashboardStore } = useRootStore();
  if (dashboardStore === undefined) {
    throw new Error('useDashboardStore must be used within RootContextProvider');
  }
  return dashboardStore;
}

export function useEquipmentStore() {
  const { equipmentStore } = useRootStore();
  if (equipmentStore === undefined) {
    throw new Error('useEquipmentStore must be used within RootContextProvider');
  }
  return equipmentStore;
}

export function useEquipmentUsageStore() {
  const { equipmentUsageStore } = useRootStore();
  if (equipmentUsageStore === undefined) {
    throw new Error('useEquipmentUsageStore must be used within RootContextProvider');
  }
  return equipmentUsageStore;
}

export function useFacilityStore() {
  const { facilityStore } = useRootStore();
  if (facilityStore === undefined) {
    throw new Error('useFacilityStore must be used within RootContextProvider');
  }
  return facilityStore;
}

export function useGlobalStore() {
  const { globalStore } = useRootStore();
  if (globalStore === undefined) {
    throw new Error('useGlobalStore must be used within RootContextProvider');
  }
  return globalStore;
}

export function useSearchStore() {
  const { searchStore } = useRootStore();
  if (searchStore === undefined) {
    throw new Error('useSearchStore must be used within RootContextProvider');
  }
  return searchStore;
}

export function useUserStore() {
  const { userStore } = useRootStore();
  if (userStore === undefined) {
    throw new Error('useUserStore must be used within RootContextProvider');
  }
  return userStore;
}

export function useModelAuditLogStore() {
  const { modelAuditLogStore } = useRootStore();
  if (modelAuditLogStore === undefined) {
    throw new Error('modelAuditLogStore must be used within RootContextProvider');
  }
  return modelAuditLogStore;
}

export function RootContextProvider({ children }: Props) {
  const store: RootStore = new RootStore();

  return <RootContext.Provider value={store}>{children}</RootContext.Provider>;
}
