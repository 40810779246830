import { DataTable } from 'grommet';
import React from 'react';
import { Box, DataTableHeader, DataTableItem, LoadingSpinner, Text } from '/src/components';
import { ClientProgram } from '/src/lib/models';
import { getQuarterFromDateString } from '/src/utils';

export type ProgramCommissionsListProps = {
  clientPrograms?: ClientProgram[];
};

export const ProgramCommissionsList: React.FC<ProgramCommissionsListProps> = ({ clientPrograms }) => {
  return !clientPrograms ? (
    <LoadingSpinner />
  ) : (
    <DataTable
      pad={{ horizontal: '1.5rem' }}
      columns={[
        {
          property: 'name',
          header: <DataTableHeader>PROGRAM</DataTableHeader>,
          primary: true,
          size: '35%',
          render: (clientProgram) => <Text>{clientProgram.program.name}</Text>,
        },
        {
          property: 'percent_rate',
          size: '17%',
          header: <DataTableHeader>PERCENTAGE</DataTableHeader>,
          render: (clientProgram) => (
            <DataTableItem boxProps={{ pad: { vertical: '1rem' } }}>
              {clientProgram.percent_rate ? `${clientProgram.percent_rate}%` : '—'}
            </DataTableItem>
          ),
        },
        {
          property: 'fixed_kwh_rate',
          size: '18%',
          header: <DataTableHeader>FIXED RATE</DataTableHeader>,
          render: (clientProgram) => (
            <DataTableItem boxProps={{ pad: { vertical: '1rem' } }}>
              {clientProgram.fixed_kwh_rate ? (
                <Box row gap="xsmall">
                  <Text>${`${clientProgram.fixed_kwh_rate}`}</Text>
                  <Text>/ kWh</Text>
                </Box>
              ) : (
                '—'
              )}
            </DataTableItem>
          ),
        },
        {
          property: 'start_reporting_quarter',
          size: '30%',
          header: <DataTableHeader>EFFECTIVE START DATE</DataTableHeader>,
          render: (clientProgram) => (
            <DataTableItem>{getQuarterFromDateString(clientProgram.start_reporting_quarter)}</DataTableItem>
          ),
        },
      ]}
      data={clientPrograms}
      background={['light-6', 'white']}
    />
  );
};
