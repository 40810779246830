import { SelectMultiple as GrommetSelectMultiple } from 'grommet';
import React from 'react';
import { Box, BoxProps, Text } from '/src/components';

export const SelectMultiple: React.FC<SelectMultipleProps> = (props) => {
  const {
    label,
    values,
    setValues,
    error,
    id,
    placeholder,
    required,
    hideOptionalText,
    disabled,
    options,
    ...boxProps
  } = props;

  return (
    <Box {...boxProps}>
      <Box
        border={disabled ? undefined : { color: error ? 'red' : 'light-5', size: '0.8px' }}
        background={disabled ? 'light-6' : undefined}
        borderRadius="6px"
        pad={{ horizontal: '0.75rem', vertical: '0.5rem' }}
      >
        <Text size="small" color={disabled ? 'light-3' : 'accent-1'} lineHeight="0.875rem">
          {label}
        </Text>
        <GrommetSelectMultiple
          id={id || `${label.toLowerCase()}-input`}
          placeholder={placeholder || 'Choose...'}
          required={required}
          plain
          labelKey="label"
          valueKey={{ key: 'value', reduce: true }}
          value={values}
          style={{
            fontSize: '0.875rem',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 400,
            padding: 0,
          }}
          options={options}
          onChange={({ value: nextValues }) => setValues(nextValues)}
          disabled={disabled}
        />
      </Box>
      {error && (
        <Box margin={{ top: '0.5rem' }}>
          <Text color="red" size="0.75rem">
            {error}
          </Text>
        </Box>
      )}
      {!required && !hideOptionalText && (
        <Box margin={{ top: '0.5rem' }}>
          <Text color="dark-1" size="0.75rem">
            OPTIONAL
          </Text>
        </Box>
      )}
    </Box>
  );
};

export type SelectMultipleProps = BoxProps & {
  label: string;
  values: (string | number)[];
  setValues: (values: (string | number)[]) => void; 
  options: (Record<string, string | number> | string)[];
  error?: string;
  id?: string;
  placeholder?: string;
  required?: boolean;
  hideOptionalText?: boolean;
  disabled?: boolean;
};