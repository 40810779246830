import { Button } from 'grommet';
import { useState } from 'react';
import { Box, BoxProps, Text } from '/src/components';

export const Tabs: React.FC<TabsProps> = (props) => {
  const { titles, activeTab, setActiveTab, ...boxProps } = props;

  const [hoverTab, setHoverTab] = useState<number>();

  return (
    <Box
      flex="grow"
      direction="row"
      gap="0.25rem"
      background="light"
      pad={{ horizontal: 'medium', top: '2rem' }}
      {...boxProps}
    >
      {titles.map((title, i) => (
        <Button
          key={title}
          onClick={() => setActiveTab(i)}
          onMouseOver={() => setHoverTab(i)}
          onMouseOut={() => setHoverTab(undefined)}
          style={{ cursor: activeTab === i ? 'default' : hoverTab === i ? 'pointer' : undefined }}
        >
          <Box
            pad={{ horizontal: '1.5rem', vertical: '0.75rem' }}
            border={{ color: activeTab === i || hoverTab === i ? 'light-2' : 'transparent', size: 'small' }}
            style={{
              boxShadow: activeTab === i || hoverTab === i ? 'rgba(24, 100, 158, 0.2) 0px -24px 24px -24px inset' : '',
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
              borderBottom: '0px solid white',
            }}
            transitionProperty="color, box-shadow"
            transitionDuration="0.15s, 0.15s"
            transitionTimingFunction="ease, ease"
          >
            <Text
              color={activeTab !== i ? 'accent-1' : undefined}
              size="1rem"
              lineHeight="1.5rem"
              letterSpacing="0.8px"
            >
              {title.toUpperCase()}
            </Text>
          </Box>
        </Button>
      ))}
    </Box>
  );
};

export type TabsProps = BoxProps & {
  titles: string[];
  activeTab: number;
  focusedTab: string;
  setActiveTab: (tabIndex: number) => void;
  setFocusedTab: (tabName: string) => void;
};