import { Button, Form, Grid, Keyboard, Main, ResponsiveContext, Spinner, TextInput } from 'grommet';
import { CircleAlert } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, UserService } from '../api';
import { config } from '../config';
import { DesktopLayout } from '../layouts';
import { getIsMobile, getPageTitle } from '../utils';
import { Box, Card, CardBody, CardHeader, FooterNav, FuseLogo, Link, LoadingSpinner, Text } from '/src/components';
import { useGlobalStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';

const defaultFormData: Record<string, string> = {
  password: '',
};

const ResetPassword = observer(() => {
  /** Context **/
  const globalStore = useGlobalStore();
  const navigate = useNavigate();
  const params = useParams();
  const screenSize = useContext(ResponsiveContext);
  const isMobile = getIsMobile(screenSize);

  /** State **/
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [formValues, setFormValues] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Methods **/
  const resetPassword = async () => {
    if (!params.token) return;

    setFormErrors({});
    const errors: Record<string, string> = {};
    if (!formValues.password) errors['password'] = 'Password is required';
    else if (formValues.password.length < 10) errors['password'] = 'Password must be at least 10 characters';
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    try {
      setIsLoading(true);

      await UserService.resetPassword(params.token, {
        password: formValues.password,
      });
      toast.success(toastMessages.resetPassword.success);
      navigate('/login');
    } catch (err) {
      const error = err as ApiError;
      if (error.body?.message.toLowerCase() === 'invalid token') {
        setIsValid(false);
      } else {
        globalStore.handleApiError(err as ApiError, toastMessages.resetPassword.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Reset Password');
  }, []);

  /** Render **/
  const renderErrorPage = () => (
    <DesktopLayout>
      <Box flex="grow" justify="center" align="center">
        <Card flex="shrink" width={!isMobile ? '35%' : '80%'} margin={{ bottom: 'xlarge' }}>
          <CardHeader title="Invalid Token" icon={<CircleAlert color="brand" />} />
          <CardBody>
            <Box>
              <Text fontFamily="Lato, sans-serif">
                This token is invalid or has expired. <Link to="/reset-password">Click here</Link> to request a new
                token.
              </Text>
            </Box>
          </CardBody>
        </Card>
      </Box>
    </DesktopLayout>
  );

  const renderResetPage = () => (
    <Grid height="100vh" rows={['flex']} columns={['auto']} areas={[{ name: 'main', start: [0, 0], end: [0, 0] }]}>
      <Main gridArea="main" background="light">
        <Box justify="center" flex="grow">
          <Box alignSelf="center" margin={{ bottom: '2rem' }}>
            <FuseLogo width="14rem" height="3.75rem" />
          </Box>
          <Card alignSelf="center" width="28rem" flex={undefined}>
            <Form>
              <CardBody flex="grow">
                <Box margin={{ bottom: '0.5rem' }} align="center">
                  <Text size="xlarge" weight={300}>
                    Update Password
                  </Text>
                </Box>
                <Box margin={{ bottom: '0.5rem' }} align="center">
                  <Text textAlign="center" fontFamily="Lato, sans-serif">
                    Enter a new password for your account. Passwords must be at least 10 characters.
                  </Text>
                </Box>

                <Box>
                  {formErrors['password'] && (
                    <Text color="red" size="small">
                      {formErrors['password']}
                    </Text>
                  )}
                  <Keyboard onEnter={resetPassword}>
                    <TextInput
                      name="new_password"
                      type="password"
                      placeholder="Password"
                      autoComplete="password"
                      required
                      value={formValues.password}
                      onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                      style={{ borderRadius: '0.375rem', fontFamily: 'Lato, sans-serif' }}
                    />
                  </Keyboard>
                </Box>
                <Button
                  type="submit"
                  disabled={!formValues.password}
                  onClick={resetPassword}
                  margin={{ bottom: '1rem' }}
                >
                  <Box background="accent-1" pad={{ horizontal: '1rem', vertical: '0.5rem' }} borderRadius="0.5rem">
                    {!isLoading && (
                      <Text alignSelf="center" size="1.25rem" color="white" lineHeight="1.875rem">
                        UPDATE PASSWORD
                      </Text>
                    )}
                    {isLoading && <Spinner height="1.875rem" width="1.875rem" alignSelf="center" color="white" />}
                  </Box>
                </Button>
              </CardBody>
            </Form>
          </Card>
        </Box>
        <FooterNav flex="shrink" />
      </Main>
    </Grid>
  );

  return isLoading ? <LoadingSpinner /> : isValid ? renderResetPage() : renderErrorPage();
});

export default ResetPassword;
