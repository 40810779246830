import { CardBody } from 'grommet';
import { Box, Card, CardProps, LoadingSpinner, Text } from '/src/components';

const cardHeight = '8.44375rem';

export const DashboardInfoCard: React.FC<DashboardInfoCardProps> = (props) => {
  /* Props */
  const { title, value, subtitle, description, icon, height, width, isLoading, showLoadingSpinner, ...cardProps } =
    props;

  /* Render */
  return showLoadingSpinner && (isLoading ?? !value) ? (
    <LoadingSpinner size="medium" />
  ) : (
    <Card height={height || cardHeight} width={width} {...cardProps}>
      <CardBody direction="row" pad={{ horizontal: '1.5rem', vertical: '1.25rem' }} justify="between">
        <Box title={title} alignSelf="center">
          <Box margin={{ bottom: '0.25rem' }} direction="row" gap="0.25rem">
            <Text size="xxlarge" color="accent-4" weight={300} lineHeight="2.5rem" letterSpacing="-0.8px">
              {value}
            </Text>
            {subtitle && (
              <Text alignSelf="end" size="1.75rem" color="accent-4" weight={300} lineHeight="1.75rem">
                {subtitle}
              </Text>
            )}
          </Box>
          <Text size="large" color="dark-2">
            {title}
          </Text>
          {description && (
            <Text size="medium" weight={300} color="dark-1">
              {description}
            </Text>
          )}
        </Box>
        {icon && <Box alignSelf="center">{icon}</Box>}
      </CardBody>
    </Card>
  );
};

export type DashboardInfoCardProps = CardProps & {
  title: string;
  value: string | number;
  subtitle?: string;
  description?: string;
  icon?: JSX.Element;
  height?: string;
  width?: string;
  isLoading?: boolean;
  showLoadingSpinner?: boolean;
};
