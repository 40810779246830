import { Accordion, AccordionPanel, CardBody } from 'grommet';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { ClientService } from '/src/api';
import {
  Box,
  Card,
  CardHeader,
  CommissionIcon,
  ProgramCommissionsList,
  SalesCommissionsList,
  Text,
} from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { ClientProgram, SalesCommission, TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';

export type CommissionsListProps = {
  id?: string;
  title?: string;
  clientId?: TClientId;
  clientPrograms?: ClientProgram[];
  salesCommissions?: SalesCommission[];
};

export const CommissionsList: React.FC<CommissionsListProps> = observer((props) => {
  const { id, title, clientId, clientPrograms: clientProgramsProp, salesCommissions: salesCommissionsProp } = props;

  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /** State **/
  const [clientPrograms, setClientPrograms] = useState<ClientProgram[]>();
  const [salesCommissions, setSalesCommissions] = useState<SalesCommission[]>();
  const [activeIndexes, setActiveIndexes] = useState([0, 1]);

  /** Computed **/
  const isLoading = !clientPrograms || !salesCommissions;

  /** Methods **/
  const fetchCommissions = () => {
    if (!userStore.isInternalUser) return;

    if (clientProgramsProp && salesCommissionsProp) {
      setClientPrograms(clientProgramsProp);
      setSalesCommissions(salesCommissionsProp);
    } else if (clientId) {
      ClientService.get({ id: clientId })
        .then((client) => {
          setClientPrograms(client.client_programs);
          setSalesCommissions(client.sales_commission_rates);
        })
        .catch((err) => globalStore.handleApiError(err, toastMessages.generic.error));
    }
  };

  /** Effects **/
  useEffect(() => {
    if (isLoading) {
      fetchCommissions();
    }
  }, [isLoading]);

  /** Render **/
  return (
    <Box id={id} direction="row" gap="medium" fill="horizontal">
      <Card>
        <CardHeader title={title || 'Commission List'} icon={<CommissionIcon />} />
        <CardBody pad="none" gap="none">
          <Box elevation="small">
            <Accordion
              activeIndex={activeIndexes}
              onActive={(activeIndexes) => setActiveIndexes(activeIndexes)}
              multiple
            >
              <AccordionPanel
                label={
                  <Box pad="medium">
                    <Text size="large">FuSE Commissions</Text>
                  </Box>
                }
              >
                <ProgramCommissionsList clientPrograms={clientPrograms} />
              </AccordionPanel>
              <AccordionPanel
                label={
                  <Box pad="medium">
                    <Text size="large">Sales Commissions</Text>
                  </Box>
                }
              >
                <SalesCommissionsList salesCommissions={salesCommissions} />
              </AccordionPanel>
            </Accordion>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
});
