import {
  Model,
  ModelAuditLogEventType,
  ModelAuditLogState,
  Role,
  TModelAuditLogEventTypeId,
  TModelAuditLogStateId,
  TModelId,
  TRoleId,
  TUserId,
  User,
} from 'src/lib/models';
import { DateString, Nullable } from 'src/lib/types';

export type TModelAuditLogId = number;

export interface ModelAuditLogUser {
  phone: string;
  new_unverified_email: Nullable<string>;
  last_login_at: DateString;
  id: TUserId;
  email: string;
  name: string;
  role_id: TRoleId;
  is_internal: boolean;
  is_active: boolean;
  created_at?: DateString;
  updated_at?: DateString;
}

export interface ModelAuditLog {
  id: TModelAuditLogId;
  created_at?: DateString;
  updated_at?: DateString;
  is_internal_user: boolean;
  role: Role;
  model: Nullable<Model>;
  model_id: TModelId;
  model_name: string;
  model_audit_log_event_type: ModelAuditLogEventType;
  model_audit_log_event_type_id: TModelAuditLogEventTypeId;
  model_audit_log_state: Nullable<ModelAuditLogState>;
  model_audit_log_state_id: Nullable<TModelAuditLogStateId>;
  resolved_by_user: Nullable<User>;
  resolved_by_user_id: Nullable<TUserId>;
  user: ModelAuditLogUser;
  user_id: TUserId;
  role_id: TRoleId;
  request_ip: string;
  request_url: string;
  request_method: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request_body: Nullable<any>;
  old_data: Nullable<Record<string, string | number>>;
  new_data: Nullable<Record<string, string | number>>;
  is_resolved?: boolean; // NOT present in backend data; calculated in `modelAuditLogStore.fetchModelAuditLogs`
}
