import type {
  CancelablePromise,
  TCreateAddressRequest,
  TCreateAddressResponse,
  TGetAddressRequest,
  TGetAddressResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class AddressService {
  /**
   * Get Address
   * @param email string
   * @param password string
   * @returns Address Address
   * @throws ApiError
   */
  public static getAddress({ id }: TGetAddressRequest): CancelablePromise<TGetAddressResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/addresses/{id}',
      path: { id },
    });
  }

  /**
   * Create Address
   * @param address Address
   * @returns Address Address
   * @throws ApiError
   */
  public static createAddress({ client_id, ...req }: TCreateAddressRequest): CancelablePromise<TCreateAddressResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clients/{client_id}/addresses',
      path: { client_id },
      body: req,
    });
  }
}
