import { StorageKeys } from '/src/lib/types';

class Storage {
  get<T = unknown>(key: StorageKeys | string, defaultValue: T | string = ''): T | string | false {
    if (typeof window !== 'undefined') {
      const value = localStorage.getItem(key);
      if (value) return JSON.parse(value) as T;
      return defaultValue;
    }
    return false;
  }

  set(key: StorageKeys | string, value: unknown): void {
    if (typeof window !== 'undefined') {
      const valueToStr = JSON.stringify(value || '');
      localStorage.setItem(key, valueToStr);
    }
  }

  remove(key: StorageKeys | string): void {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key);
    }
  }

  removeAll(): void {
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
  }
}

const storage = new Storage();

export default storage;