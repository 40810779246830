import { Form, ResponsiveContext } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, ClientService, TCreateUserRequest, UserService } from '/src/api';
import {
  Box,
  BreadcrumbNav,
  BreadcrumbNavProps,
  ClientsSelect,
  FormCard,
  FormCardSection,
  FormPage,
  Input,
  Line,
  Select,
} from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { ExternalRoles, RoleTypes, TClientId, TFacilityId, roleIdOptions } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { SelectOption, UserDataForm } from '/src/lib/types';
import { getFacilityLabel, getIsMobile, getPageTitle, getQueryParams, isValidEmail } from '/src/utils';
import { config } from '/src/config';

export const CreateUserPage = observer(() => {
  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const { search } = useLocation();
  const navigate = useNavigate();
  const screenSize = useContext(ResponsiveContext);
  const queryParams = getQueryParams(search);
  const params = useParams();
  const clientId = useMemo(() => parseInt(queryParams.client_id ?? params.client_id), [queryParams, params]);
  const facilityId = useMemo(() => parseInt(queryParams.facility_id ?? params.facility_id), [queryParams, params]);

  /** Refs **/
  const defaultFormData = useRef<UserDataForm>({
    name: '',
    email: '',
    phone: '',
    is_email_verified: true,
    role_id: NaN,
    client_id: NaN,
    facility_ids: [],
    add_facility_ids: [],
    remove_facility_ids: [],
  });

  /** State **/
  const [isCreating, setIsCreating] = useState(false);
  const [breadcrumbNavItems, setBreadcrumbNavItems] = useState<BreadcrumbNavProps['previousPages']>([]);
  const [facilityOptions, setFacilityOptions] = useState<SelectOption<TFacilityId>[]>();

  const [formValues, setFormValues] = useState<UserDataForm>({
    ...defaultFormData.current,
    client_id: clientId,
    role_id: facilityId ? RoleTypes.FacilityAdmin : clientId ? RoleTypes.ClientAdmin : RoleTypes.FuseAdmin,
    facility_ids: facilityId ? [facilityId] : [],
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Computed **/
  const isMobile = getIsMobile(screenSize);
  const isLoading = !(breadcrumbNavItems && facilityOptions);

  const filteredRoleIdOptions = useMemo(() => {
    // filter out Sales Rep role for the time being
    const options = roleIdOptions.filter((option) => option.value !== RoleTypes.FuseSalesRep);

    return userStore.isClientUser ? options.filter((option) => ExternalRoles.includes(option.value)) : options;
  }, [userStore.isClientUser, roleIdOptions]);

  /** Methods **/
  const updateFormValue = (key: string, value: unknown) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  const fetchPageData = async (clientId: TClientId) => {
    try {
      const client = userStore.isFacilityUser
        ? userStore.user?.clients.find((client) => client.id === clientId)
        : await ClientService.get({ id: clientId });

      const facilities = userStore.isFacilityUser ? userStore.user?.facilities : client?.facilities;

      if (!client || !facilities) return;

      setBreadcrumbNavItems([
        { name: 'Clients', link: userStore.isInternalUser ? '/clients' : undefined },
        { name: client.name, link: !userStore.isFacilityUser ? `/clients/${client.id}` : undefined },
        { name: 'Users', link: userStore.isExternalUser && client ? `/clients/${client.id}/users` : undefined },
      ]);

      setFacilityOptions(
        facilities.map((facility) => {
          return {
            label: getFacilityLabel(
              { ...facility, address_region: globalStore.getRegionById(facility.address_region_id) },
              true
            ),
            value: facility.id,
          };
        })
      );
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchClient.error);
    }
  };

  const createUser = async () => {
    try {
      setIsCreating(true);

      // Validate
      const errors: Record<string, string> = {};
      if (!formValues.name) errors['name'] = 'Name is required';
      else if (formValues.name.length > 50) errors['name'] = 'Name cannot be more than 50 characters';
      if (!formValues.email) errors['email'] = 'Email is required';
      else if (!isValidEmail(formValues.email)) errors['email'] = 'Must be a valid email address';
      else if (formValues.email.length > 100) errors['email'] = 'Email cannot be more than 100 characters';
      if (formValues.phone && formValues.phone.length > 50)
        errors['phone'] = 'Phone number cannot be more than 50 characters';
      if (!formValues.role_id) errors['role_id'] = 'Role is required';
      if (ExternalRoles.includes(formValues.role_id) && !formValues.client_id)
        errors['client_id'] = 'Client is required';
      if (formValues.role_id === RoleTypes.FacilityAdmin && !formValues.facility_ids.length)
        errors['facility_ids'] = 'Must select at least one Facility';
      if (Object.keys(errors).length) {
        setFormErrors(errors);
        return;
      }

      const newUser: TCreateUserRequest = {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone || null,
        role_id: formValues.role_id,
        is_email_verified: formValues.is_email_verified,
      };

      if (ExternalRoles.includes(formValues.role_id)) newUser.client_id = formValues.client_id;
      if (formValues.role_id === RoleTypes.FacilityAdmin) newUser.facility_ids = formValues.facility_ids;

      const promises: Promise<any>[] = [UserService.create(newUser)]; // eslint-disable-line @typescript-eslint/no-explicit-any

      const [user] = await Promise.all(promises);

      toast.success(toastMessages.createUser.success);
      navigate(`/users/${user.id}`);
    } catch (err) {
      const apiErrors: Record<string, string>[] = (err as ApiError).body?.errors;
      if (apiErrors) {
        const errors = { ...formErrors };
        for (const apiError of apiErrors) {
          const { field, rule } = apiError as Record<string, string>;

          if (rule === 'unique') {
            if (field === 'email') {
              errors[field] = 'This email address is already in use';
            } else {
              errors[field] = `This ${field} is already in use`;
            }
          }
        }
        setFormErrors(errors);
      } else {
        globalStore.handleApiError(err as ApiError, toastMessages.createUser.error);
      }
    } finally {
      setIsCreating(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Add User');
  }, []);

  useEffect(() => {
    if (filteredRoleIdOptions && !formValues.role_id) {
      setFormValues({
        ...formValues,
        role_id: filteredRoleIdOptions[0]?.value,
      });
    }
  }, [filteredRoleIdOptions, formValues.role_id]);

  useEffect(() => {
    if (formValues.client_id) {
      fetchPageData(formValues.client_id);
    } else {
      setBreadcrumbNavItems([{ name: 'Users', link: userStore.isInternalUser ? '/users' : undefined }]);
      setFacilityOptions([]);
    }
  }, [formValues.client_id]);

  /** Render **/
  return (
    <FormPage
      title="Add User"
      breadcrumbNav={<BreadcrumbNav previousPages={breadcrumbNavItems} currentPageName="Add User" />}
      isLoading={isLoading}
    >
      <FormCard
        title="User Information"
        icon={<CircleInformation width="24px" height="24px" color="brand" />}
        isLoading={isLoading || isCreating}
        onSubmit={createUser}
      >
        <Form>
          <Box direction={isMobile ? 'column' : 'row'} gap="1rem">
            <Box fill="horizontal" gap="1rem">
              <FormCardSection title="Contact Information">
                <Input
                  name="user_name"
                  label="Name"
                  maxLength={50}
                  value={formValues.name}
                  setValue={(value) => updateFormValue('name', value)}
                  error={formErrors['name']}
                  onSubmit={createUser}
                  required
                />
                <Input
                  name="user_email"
                  label="Email"
                  maxLength={100}
                  value={formValues.email}
                  setValue={(value) => updateFormValue('email', value)}
                  error={formErrors['email']}
                  onSubmit={createUser}
                  type="email"
                  required
                />
                <Input
                  name="user_phone"
                  label="Phone"
                  maxLength={50}
                  value={formValues.phone}
                  setValue={(value) => updateFormValue('phone', value)}
                  error={formErrors['phone']}
                  onSubmit={createUser}
                />
              </FormCardSection>
            </Box>
            {isMobile ? <Line margin="0.5rem" /> : <Line direction="vertical" margin="3rem" />}
            <Box fill="horizontal" gap="1rem">
              <FormCardSection title="User Role">
                <Select
                  name="role_id"
                  label="User Role"
                  value={formValues.role_id}
                  setValue={(value) => updateFormValue('role_id', value)}
                  error={formErrors['role_id']}
                  options={filteredRoleIdOptions ?? []}
                  required
                />
                {ExternalRoles.includes(formValues.role_id) && (
                  <ClientsSelect
                    selectedClientId={formValues.client_id}
                    setSelectedClientId={(value) => updateFormValue('client_id', value)}
                    error={formErrors['client_id']}
                    disabled={userStore.isExternalUser}
                    hasSearch
                    required
                  />
                )}
                {formValues.role_id === RoleTypes.FacilityAdmin && !!facilityOptions?.length && (
                  <Select
                    name="facility_ids"
                    multiple
                    label="Facilities"
                    values={formValues.facility_ids}
                    setValues={(value) => updateFormValue('facility_ids', value)}
                    options={facilityOptions}
                    error={formErrors['facility_ids']}
                    required
                  />
                )}
              </FormCardSection>
            </Box>
          </Box>
        </Form>
      </FormCard>
    </FormPage>
  );
});
