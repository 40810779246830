import type {
  CancelablePromise,
  TGetEquipmentUsageRequest,
  TGetEquipmentUsageResponse,
  TListEquipmentUsagesInFacilityAndPeriodRequest,
  TListEquipmentUsagesInFacilityAndPeriodResponse,
  TListEquipmentUsagesRequest,
  TListEquipmentUsagesResponse,
  TUpdateMeteredEquipmentUsageRequest,
  TUpdateMultipleMeteredEquipmentUsagesRequest,
  TUpdateMultipleUnmeteredEquipmentUsagesRequest,
  TUpdateUnmeteredEquipmentUsageRequest,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class EquipmentUsageService {
  /**
   * List Equipment Usages
   * @returns {EquipmentUsage[]}
   * @throws ApiError
   */
  public static list({
    equipment_id,
    ...req
  }: TListEquipmentUsagesRequest): CancelablePromise<TListEquipmentUsagesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/equipment/{equipment_id}/equipmentUsages',
      path: { equipment_id },
      query: req,
    });
  }

  /**
   * List Equipment Usages by Facility Reporting Period
   * @returns {EquipmentUsage[]}
   * @throws ApiError
   */
  public static listByFacilityReportingPeriod({
    facility_id,
    client_reporting_period_id,
    ...req
  }: TListEquipmentUsagesInFacilityAndPeriodRequest): CancelablePromise<TListEquipmentUsagesInFacilityAndPeriodResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/facilities/{facility_id}/clientReportingPeriods/{client_reporting_period_id}/equipmentUsages',
      path: { facility_id, client_reporting_period_id },
      query: req,
    });
  }

  /**
   * Get Equipment Usage
   * @returns EquipmentUsage EquipmentUsage
   * @throws ApiError
   */
  public static get({ id }: TGetEquipmentUsageRequest): CancelablePromise<TGetEquipmentUsageResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/equipmentUsages/{id}',
      path: { id },
    });
  }

  /**
   * Update - Metered Equipment Usage
   * @returns void
   * @throws ApiError
   */
  public static updatedMetered({
    equipment_usage_id,
    ...req
  }: TUpdateMeteredEquipmentUsageRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/equipmentUsages/{equipment_usage_id}/metered',
      path: { equipment_usage_id },
      body: req,
    });
  }

  /**
   * Update - Multiple Metered Equipment Usage
   * @returns void
   * @throws ApiError
   */
  public static updateMultipleMetered({
    facility_id,
    client_reporting_period_id,
    ...req
  }: TUpdateMultipleMeteredEquipmentUsagesRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/facilities/{facility_id}/clientReportingPeriods/{client_reporting_period_id}/equipmentUsages/multipleMetered',
      path: { facility_id, client_reporting_period_id },
      body: req,
    });
  }

  /**
   * Update - Unmetered Equipment Usage
   * @returns void
   * @throws ApiError
   */
  public static updatedUnmetered({
    equipment_usage_id,
    ...req
  }: TUpdateUnmeteredEquipmentUsageRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/equipmentUsages/{equipment_usage_id}/unmetered',
      path: { equipment_usage_id },
      body: req,
    });
  }

  /**
   * Update - Multiple Unmetered Equipment Usage
   * @returns void
   * @throws ApiError
   */
  public static updateMultipleUnmetered({
    facility_id,
    client_reporting_period_id,
    ...req
  }: TUpdateMultipleUnmeteredEquipmentUsagesRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/facilities/{facility_id}/clientReportingPeriods/{client_reporting_period_id}/equipmentUsages/multipleUnmetered',
      path: { facility_id, client_reporting_period_id },
      body: req,
    });
  }
}
