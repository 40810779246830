import { Button, CardBody, DataTable, Pagination } from 'grommet';
import { Menu, Organization } from 'grommet-icons';
import FileTypeCSV from 'jsx:/public/images/filetype-csv.svg';
import FileTypeDOC from 'jsx:/public/images/filetype-doc.svg';
import FileTypeDOCX from 'jsx:/public/images/filetype-docx.svg';
import FileTypePDF from 'jsx:/public/images/filetype-pdf.svg';
import FileTypeTXT from 'jsx:/public/images/filetype-txt.svg';
import FileTypeXLS from 'jsx:/public/images/filetype-xls.svg';
import FileTypeXLSX from 'jsx:/public/images/filetype-xlsx.svg';
import React from 'react';
import { AddButton, Box, Card, CardHeader, DataTableHeader, DataTableItem, Text } from '/src/components';
import { ClientFile, ClientFileType } from '/src/lib/models';
import { TResponseMetadata } from '/src/lib/types';

export const ClientFileList: React.FC<ClientFileListProps> = (props) => {
  const { clientFiles, metadata, currentPage, setCurrentPage, showAddButton } = props;

  const renderEditButton = () => (
    <Button>
      <Box borderRadius="6px" direction="row" justify="center" pad={{ vertical: '0.5rem' }}>
        <Menu size="14px" color="accent-1" />
      </Box>
    </Button>
  );

  const renderFileTypeIcon = (clientFile: ClientFile) => (
    <Box alignSelf="center" width="20px" height="20px">
      {clientFile.type === ClientFileType.CSV && <FileTypeCSV />}
      {clientFile.type === ClientFileType.DOC && <FileTypeDOC />}
      {clientFile.type === ClientFileType.DOCX && <FileTypeDOCX />}
      {clientFile.type === ClientFileType.PDF && <FileTypePDF />}
      {clientFile.type === ClientFileType.TXT && <FileTypeTXT />}
      {clientFile.type === ClientFileType.XLS && <FileTypeXLS />}
      {clientFile.type === ClientFileType.XLSX && <FileTypeXLSX />}
    </Box>
  );

  return (
    <Card>
      <CardHeader title="Documents List" icon={<Organization size="24px" color="brand" />}>
        {showAddButton && <AddButton label="Add Record" />}
      </CardHeader>
      <CardBody>
        <Box elevation="small">
          <DataTable
            columns={[
              {
                property: '',
                header: <Box height="1rem" margin={{ vertical: '1rem' }} />,
                render: renderEditButton,
              },
              {
                property: 'name',
                primary: true,
                header: <DataTableHeader>NAME</DataTableHeader>,
                render: (clientFile) => <DataTableItem>{clientFile.name}</DataTableItem>,
              },
              {
                property: 'type',
                header: <DataTableHeader>TYPE</DataTableHeader>,
                render: renderFileTypeIcon,
              },
              {
                property: 'date',
                header: <DataTableHeader>DATE</DataTableHeader>,
                // render: (clientFile) => (
                //   <DataTableItem>{dayjs(clientFile.created_at).format('MMM DD YYYY').toUpperCase()}</DataTableItem>
                // ),
              },
              {
                property: 'size',
                header: <DataTableHeader>SIZE</DataTableHeader>,
                // render: (clientFile) => <DataTableItem>{!!clientFile.size && `${clientFile.size} MB`}</DataTableItem>,
              },
              {
                property: 'category',
                header: <DataTableHeader>CATEGORY</DataTableHeader>,
                // render: (clientFile) => (
                //   <Box direction="row">
                //     <Tag>{clientFile.category}</Tag>
                //   </Box>
                // ),
              },
            ]}
            data={clientFiles}
            background={['light-6', 'white']}
            border={{ color: 'light-2', side: 'bottom', size: '0.05rem' }}
          />
          {!clientFiles?.length && (
            <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
              <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                No documents found.
              </Text>
            </Box>
          )}
        </Box>
        {!!metadata && !!currentPage && !!setCurrentPage && (
          <Box pad="1.5rem" flex="grow">
            <Pagination
              alignSelf="center"
              size="small"
              page={currentPage}
              step={metadata?.per_page}
              numberItems={metadata?.total}
              onChange={(e) => setCurrentPage(e.page)}
            />
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export type ClientFileListProps = {
  clientFiles: ClientFile[];
  metadata?: TResponseMetadata;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  showAddButton?: boolean;
};
