import dayjs from 'dayjs';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import {
  EquipmentCategoryService,
  EquipmentService,
  FSERegistrationStatusService,
  TCreateEquipmentExternalRequest,
  TCreateEquipmentInternalRequest,
  TUpdateNonFinalizedEquipmentInternalRequest,
  TUpdatePendingEquipmentExternalRequest,
} from '/src/api';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import {
  Equipment,
  EquipmentCategory,
  EquipmentUsage,
  FSERegistrationStatus,
  Facility,
  TEquipmentId,
  TFacilityId,
  TProgramId,
} from '/src/lib/models';
import {
  DateString,
  EquipmentDataForm,
  FSERegistrationStatusIds,
  FormMetadata,
  TResponseMetadata,
} from '/src/lib/types';
import { getFormErrors, getIsCanada } from '/src/utils';

export default class EquipmentStore extends BaseStore {
  // Equipment
  equipment?: Equipment;
  equipmentCategories?: EquipmentCategory[];
  equipmentList?: Equipment[];
  equipmentListMeta?: TResponseMetadata;
  equipmentUsagesList?: EquipmentUsage[];
  equipmentUsagesListMeta?: TResponseMetadata;
  facilityEquipmentUsagesList?: EquipmentUsage[];
  facilityEquipmentUsagesListMeta?: TResponseMetadata;
  meteredEquipmentList?: Equipment[];
  meteredEquipmentListMeta?: TResponseMetadata;

  // FSE Registration Statuses
  fseRegistrationStatuses?: FSERegistrationStatus[];

  // Forms
  formValues: EquipmentDataForm = this.defaultFormValues;
  formErrors: Record<string, string> = {};

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      equipment: observable,
      equipmentCategories: observable,
      equipmentList: observable,
      equipmentListMeta: observable,
      equipmentUsagesList: observable,
      equipmentUsagesListMeta: observable,
      facilityEquipmentUsagesList: observable,
      facilityEquipmentUsagesListMeta: observable,
      meteredEquipmentList: observable,
      meteredEquipmentListMeta: observable,
      fseRegistrationStatuses: observable,
      formValues: observable,
      formErrors: observable,
      defaultFormValues: computed,
      isApproved: computed,
    });
  }

  get defaultFormValues(): EquipmentDataForm {
    return {
      facility_id: NaN,
      category_name: '',
      equipment_category_id: NaN,
      equipment_type_id: undefined,
      unit_number: '',
      serial_number: '',
      manufacturer: '',
      model_number: '',
      model_year: '',
      is_metered: true,
      latitude: '',
      longitude: '',
      fse_registration_status_id: NaN,
      fse_id: '',
      fse_ru: '',
      comments: '',
      first_day_in_service: '',
      first_active_reporting_quarter: '',
      last_active_reporting_quarter: null,
    };
  }

  get isApproved() {
    return (
      this.formValues.fse_registration_status_id === FSERegistrationStatusIds.Approved ||
      this.formValues.fse_registration_status_id === FSERegistrationStatusIds.Paused
    );
  }

  /**
   * Forms
   */

  setFormValues = (formValues: EquipmentDataForm) => {
    runInAction(() => {
      this.formValues = formValues;
    });
  };

  setFormErrors = (formErrors: Record<string, string>) => {
    runInAction(() => {
      this.formErrors = formErrors;
    });
  };

  updateFormValue = (key: string, value: unknown) => {
    this.setFormValues({
      ...this.formValues,
      [key]: value,
    });
    if (this.formErrors[key])
      this.setFormErrors({
        ...this.formErrors,
        [key]: '',
      });
  };

  resetForm = () => {
    this.setFormValues(this.defaultFormValues);
    this.setFormErrors({});
  };

  /**
   * CRUD
   */

  listEquipment = async (facilityId: TFacilityId) => {
    if (!this.rootStore.userStore.user) return;

    const { meta, data } = await EquipmentService.list({ facility_id: facilityId });

    runInAction(() => {
      this.equipmentList = data;
      this.equipmentListMeta = meta;
    });
  };

  fetchEquipment = async (id: TEquipmentId) => {
    if (!this.rootStore.userStore.user) return;

    const equipment = await EquipmentService.get({ id });

    runInAction(() => {
      this.equipment = equipment;
    });

    return equipment;
  };

  fetchEquipmentCategories = async (programId?: TProgramId) => {
    if (!this.rootStore.userStore.user) return;

    const categories = await EquipmentCategoryService.listEquipmentCategories({ program_id: programId });

    runInAction(() => {
      this.equipmentCategories = categories;
    });

    return categories;
  };

  listMeteredEquipment = async (facilityId: TFacilityId) => {
    if (!this.rootStore.userStore.user) return;

    const { meta, data } = await EquipmentService.list({ facility_id: facilityId });

    runInAction(() => {
      this.meteredEquipmentListMeta = meta;
      this.meteredEquipmentList = data;
    });

    return data;
  };

  fetchFSERegistrationStatuses = async () => {
    if (!this.rootStore.userStore.user) return;

    const data = await FSERegistrationStatusService.listFSERegistrationStatuses();

    runInAction(() => {
      this.fseRegistrationStatuses = data;
    });
  };

  clearMeteredEquipment = () => {
    runInAction(() => {
      this.meteredEquipmentList = undefined;
      this.meteredEquipmentListMeta = undefined;
    });
  };

  /**
   * Create Equipment - Internal Users
   *
   * @param facility Facility this Equipment belongs to.
   * @param formValues Equipment data.
   * @returns Promise<void>
   */
  createEquipmentInternal = async (facility: Facility) => {
    if (!this.rootStore.userStore.isInternalUser) return;

    const formFields: FormMetadata = {
      facility_id: { label: 'Facility', required: true },
      category_name: { label: 'Equipment category', required: true },
      equipment_type_id: { label: 'Equipment type', required: true },
      unit_number: { label: 'Unit number', max: 100 },
      serial_number: { label: 'Serial number', max: 50, required: true },
      manufacturer: { label: 'Manufacturer', max: 100, required: true },
      model_number: { label: 'Model number', max: 50 },
      model_year: { label: 'Model year', minValue: 1900, maxValue: 3000, required: true },
      latitude: { label: 'Latitude', required: true },
      longitude: { label: 'Longitude', required: true },
      fse_registration_status_id: { label: 'FSE registration status', required: true },
      fse_ru: { label: 'FSE RU', max: 100 },
      comments: { label: 'Comments', max: 100 },
    };

    const facilityRegionCode = facility.address_region?.short_code?.toLowerCase();
    if (facilityRegionCode === 'or' || facilityRegionCode === 'wa') {
      const nextPeriodStartDate: DateString = dayjs(this.formValues.first_active_reporting_quarter)
        .add(getIsCanada(facility.address_region) ? 12 : 3, 'months')
        .format('YYYY-MM-DD');

      formFields.first_day_in_service = {
        label: 'First day in service',
        required: true,
        before: nextPeriodStartDate,
      };
    }

    if (this.isApproved) {
      formFields.first_active_reporting_quarter = { label: 'First reporting period', required: true };
      formFields.fse_id = {
        label: 'FSE ID',
        max: 50,
        required: true,
      };
    }

    const errors = getFormErrors(formFields, this.formValues);
    if (errors) {
      this.setFormErrors(errors);
      return;
    }

    const newEquipment: TCreateEquipmentInternalRequest = {
      facility_id: this.formValues.facility_id ?? 0,
      equipment_type_id: this.formValues.equipment_type_id ?? 0,
      unit_number: this.formValues.unit_number || null,
      serial_number: this.formValues.serial_number,
      manufacturer: this.formValues.manufacturer,
      model_number: this.formValues.model_number || null,
      model_year: parseInt(this.formValues.model_year),
      is_metered: this.formValues.is_metered,
      latitude: parseFloat(this.formValues.latitude).toFixed(6),
      longitude: parseFloat(this.formValues.longitude).toFixed(6),
      fse_registration_status_id: this.formValues.fse_registration_status_id,
      fse_id: this.formValues.fse_id || null,
      fse_ru: this.formValues.fse_ru || null,
      comments: this.formValues.comments || null,
      first_day_in_service: this.formValues.first_day_in_service || null,
      first_active_reporting_quarter: this.formValues.first_active_reporting_quarter || null,
    };

    const result = await EquipmentService.createInternal(newEquipment);

    return result;
  };

  /**
   * Create Equipment - External Users
   *
   * @param facility Facility this Equipment belongs to.
   * @param formValues Equipment data.
   * @returns Promise<void>
   */
  createEquipmentExternal = async (facility: Facility) => {
    if (!this.rootStore.userStore.isExternalUser) return;

    const formFields: FormMetadata = {
      facility_id: { label: 'Facility', required: true },
      equipment_type_id: { label: 'Equipment type', required: true },
      category_name: { label: 'Equipment category', required: true },
      unit_number: { label: 'Unit number', max: 100 },
      serial_number: { label: 'Serial number', max: 50, required: true },
      manufacturer: { label: 'Manufacturer', max: 100, required: true },
      model_number: { label: 'Model number', max: 50 },
      model_year: { label: 'Model year', minValue: 1900, maxValue: 3000, required: true },
      latitude: { label: 'Latitude', required: true },
      longitude: { label: 'Longitude', required: true },
      comments: { label: 'Comments', max: 100 },
    };

    const facilityRegionCode = facility.address_region?.short_code?.toLowerCase();
    if (facilityRegionCode === 'or' || facilityRegionCode === 'wa') {
      const nextPeriodStartDate: DateString = dayjs(this.formValues.first_active_reporting_quarter)
        .add(getIsCanada(facility.address_region) ? 12 : 3, 'months')
        .format('YYYY-MM-DD');

      formFields.first_day_in_service = {
        label: 'First day in service',
        required: true,
        before: nextPeriodStartDate,
      };
    }

    const errors = getFormErrors(formFields, this.formValues);
    if (errors) {
      this.setFormErrors(errors);
      return;
    }

    const newEquipment: TCreateEquipmentExternalRequest = {
      facility_id: this.formValues.facility_id ?? -1,
      equipment_type_id: this.formValues.equipment_type_id ?? -1,
      unit_number: this.formValues.unit_number || null,
      serial_number: this.formValues.serial_number,
      manufacturer: this.formValues.manufacturer,
      model_number: this.formValues.model_number || null,
      model_year: parseInt(this.formValues.model_year),
      first_active_reporting_quarter: this.formValues.first_active_reporting_quarter || null,
      first_day_in_service: this.formValues.first_day_in_service || null,
      is_metered: this.formValues.is_metered,
      latitude: parseFloat(this.formValues.latitude).toFixed(6),
      longitude: parseFloat(this.formValues.longitude).toFixed(6),
      comments: this.formValues.comments || null,
    };

    return await EquipmentService.createExternal(newEquipment);
  };

  /**
   * Update Non-Finalized Equipment - Internal Users
   */
  updateNonFinalizedInternal = async (equipment: Equipment) => {
    if (!this.rootStore.userStore.isInternalUser) return;

    const formFields: FormMetadata = {
      category_name: { label: 'Equipment category', required: true },
      equipment_type_id: { label: 'Equipment type', required: true },
      unit_number: { label: 'Unit number', max: 100 },
      serial_number: { label: 'Serial number', max: 50, required: true },
      manufacturer: { label: 'Manufacturer', max: 100, required: true },
      model_number: { label: 'Model number', max: 50 },
      model_year: { label: 'Model year', minValue: 1900, maxValue: 3000, required: true },
      first_active_reporting_quarter: { label: 'First reporting period', required: true },
      latitude: { label: 'Latitude', required: true },
      longitude: { label: 'Longitude', required: true },
      fse_registration_status_id: { label: 'FSE registration status', required: true },
      fse_ru: { label: 'FSE RU', max: 100 },
      comments: { label: 'Comments', max: 100 },
    };

    if (this.isApproved) {
      formFields.fse_id = {
        label: 'FSE ID',
        max: 50,
        required: true,
      };
    }

    const facilityRegionCode = equipment.facility?.address_region?.short_code?.toLowerCase();
    if (facilityRegionCode === 'or' || facilityRegionCode === 'wa') {
      const nextPeriodStartDate: DateString = dayjs(this.formValues.first_active_reporting_quarter)
        .add(getIsCanada(equipment.facility?.address_region) ? 12 : 3, 'months')
        .format('YYYY-MM-DD');

      formFields.first_day_in_service = {
        label: 'First day in service',
        required: true,
        before: nextPeriodStartDate,
      };
    }

    const errors = getFormErrors(formFields, this.formValues);
    if (errors) {
      this.setFormErrors(errors);
      return;
    }

    this.setFormErrors({});

    const updatedEquipment: TUpdateNonFinalizedEquipmentInternalRequest = {
      id: equipment.id,
      equipment_type_id: this.formValues.equipment_type_id ?? -1,
      unit_number: this.formValues.unit_number || null,
      serial_number: this.formValues.serial_number,
      manufacturer: this.formValues.manufacturer,
      model_number: this.formValues.model_number || null,
      model_year: parseInt(this.formValues.model_year),
      first_active_reporting_quarter: this.formValues.first_active_reporting_quarter || null,
      first_day_in_service: this.formValues.first_day_in_service || null,
      is_metered: this.formValues.is_metered,
      latitude: parseFloat(this.formValues.latitude).toFixed(6),
      longitude: parseFloat(this.formValues.longitude).toFixed(6),
      fse_registration_status_id: this.formValues.fse_registration_status_id,
      fse_id: this.formValues.fse_id || null,
      fse_ru: this.formValues.fse_ru || null,
      comments: this.formValues.comments || null,
    };

    return await EquipmentService.updateNonFinalizedInternal(updatedEquipment);
  };

  /**
   * Update Finalized Equipment - Internal Users
   */
  updateFinalizedInternal = async (equipmentId: TEquipmentId) => {
    if (!this.rootStore.userStore.isInternalUser) return;

    const formFields: FormMetadata = {
      latitude: { label: 'Latitude', required: true },
      longitude: { label: 'Longitude', required: true },
      unit_number: { label: 'Unit number', max: 100 },
      comments: { label: 'Comments', max: 100 },
    };

    const errors = getFormErrors(formFields, this.formValues);
    if (errors) {
      this.setFormErrors(errors);
      return;
    }

    return await EquipmentService.updateFinalizedInternal({
      id: equipmentId,
      latitude: parseFloat(this.formValues.latitude).toFixed(6),
      longitude: parseFloat(this.formValues.longitude).toFixed(6),
      unit_number: this.formValues.unit_number || null,
      comments: this.formValues.comments || null,
    });
  };

  /**
   * Update Pending Equipment - External Users
   */
  updatePendingExternal = async (equipment: Equipment) => {
    if (!this.rootStore.userStore.isExternalUser) return;

    const formFields: FormMetadata = {
      category_name: { label: 'Equipment category', required: true },
      equipment_type_id: { label: 'Equipment type', required: true },
      unit_number: { label: 'Unit number', max: 100 },
      serial_number: { label: 'Serial number', max: 50, required: true },
      manufacturer: { label: 'Manufacturer', max: 100, required: true },
      model_number: { label: 'Model number', max: 50 },
      model_year: { label: 'Model year', minValue: 1900, maxValue: 3000, required: true },
      first_active_reporting_quarter: { label: 'First reporting period', required: true },
      latitude: { label: 'Latitude', required: true },
      longitude: { label: 'Longitude', required: true },
      comments: { label: 'Comments', max: 100 },
    };

    const facilityRegionCode = equipment.facility?.address_region?.short_code?.toLowerCase();
    if (facilityRegionCode === 'or' || facilityRegionCode === 'wa') {
      const nextPeriodStartDate: DateString = dayjs(this.formValues.first_active_reporting_quarter)
        .add(getIsCanada(equipment.facility?.address_region) ? 12 : 3, 'months')
        .format('YYYY-MM-DD');

      formFields.first_day_in_service = {
        label: 'First day in service',
        required: true,
        before: nextPeriodStartDate,
      };
    }

    const errors = getFormErrors(formFields, this.formValues);
    if (errors) {
      this.setFormErrors(errors);
      return;
    }

    this.setFormErrors({});

    const updatedEquipment: TUpdatePendingEquipmentExternalRequest = {
      id: equipment.id,
      equipment_type_id: this.formValues.equipment_type_id ?? -1,
      unit_number: this.formValues.unit_number || null,
      serial_number: this.formValues.serial_number,
      manufacturer: this.formValues.manufacturer,
      model_number: this.formValues.model_number || null,
      model_year: parseInt(this.formValues.model_year),
      first_active_reporting_quarter: this.formValues.first_active_reporting_quarter || null,
      first_day_in_service: this.formValues.first_day_in_service || null,
      is_metered: this.formValues.is_metered,
      latitude: parseFloat(this.formValues.latitude).toFixed(6),
      longitude: parseFloat(this.formValues.longitude).toFixed(6),
      comments: this.formValues.comments || null,
    };

    return await EquipmentService.updatePendingExternal(updatedEquipment);
  };

  /**
   * Update Non-Pending Equipment - External Users
   */
  updateNonPendingExternal = async (equipmentId: TEquipmentId) => {
    if (!this.rootStore.userStore.isExternalUser) return;

    const formFields: FormMetadata = {
      latitude: { label: 'Latitude', required: true },
      longitude: { label: 'Longitude', required: true },
      unit_number: { label: 'Unit number', max: 100 },
      comments: { label: 'Comments', max: 100 },
    };

    const errors = getFormErrors(formFields, this.formValues);
    if (errors) {
      this.setFormErrors(errors);
      return;
    }

    return await EquipmentService.updateNonPendingExternal({
      id: equipmentId,
      latitude: parseFloat(this.formValues.latitude).toFixed(6),
      longitude: parseFloat(this.formValues.longitude).toFixed(6),
      unit_number: this.formValues.unit_number || null,
      comments: this.formValues.comments || null,
    });
  };
}
