import { Keyboard, SelectExtendedProps } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ClientService } from '/src/api';
import { Select } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { SelectOption } from '/src/lib/types';
import { filterSelectOptions } from '/src/utils';

export const ClientsSelect: React.FC<ClientsSelectProps> = observer((props) => {
  const { user: currentUser, isFuseAdmin } = useUserStore();
  const { handleApiError } = useGlobalStore();
  const {
    selectedClientId,
    setSelectedClientId,
    options,
    error,
    required,
    hideOptionalText,
    disabled,
    placeholder,
    searchPlaceholder = 'Filter by Company Name...',
    hasSearch,
    onSubmit,
  } = props;

  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [defaultSelectOptions, setDefaultSelectOptions] = useState<SelectOption<TClientId | undefined>[]>([]);
  const [selectOptions, setSelectOptions] = useState<SelectOption<TClientId | undefined>[]>([]);

  useEffect(() => {
    if (isLoadingClients) {
      if (options) {
        setDefaultSelectOptions(options);
        setSelectOptions(options);
        setIsLoadingClients(false);
      } else {
        fetchClientsList()
          .catch((err) => handleApiError(err, toastMessages.listClients.error))
          .finally(() => setIsLoadingClients(false));
      }
    }
  }, [isLoadingClients, options]);

  const fetchClientsList = async () => {
    if (currentUser) {
      if (isFuseAdmin) {
        const data = await ClientService.listClientNames();
        const options = data.map((client) => ({
          label: client.name,
          value: client.id,
        }));
        setDefaultSelectOptions(options);
        setSelectOptions(options);
      } else {
        const options = currentUser.clients.map((client) => ({
          label: client.name,
          value: client.id,
        }));
        setDefaultSelectOptions(options);
        setSelectOptions(options);
      }
    }
  };

  const handleSearch = (query: string) => {
    if (query === '') setSelectOptions(defaultSelectOptions);
    else setSelectOptions(filterSelectOptions<number | undefined>(query, defaultSelectOptions));
  };

  return (
    <Keyboard onEnter={onSubmit}>
      <Select
        nanme="selected_client"
        label="Company"
        value={selectedClientId}
        setValue={(id) => setSelectedClientId(id)}
        options={selectOptions}
        required={required}
        disabled={disabled}
        error={error}
        onSearch={hasSearch ? handleSearch : undefined}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
        hideOptionalText={hideOptionalText}
      />
    </Keyboard>
  );
});

export type ClientsSelectProps = {
  selectedClientId?: number;
  setSelectedClientId: (index: number) => void;
  options?: { label: string; value: number | undefined }[];
  error?: string;
  required?: boolean;
  hideOptionalText?: boolean;
  style?: SelectExtendedProps['style'];
  hasSearch?: boolean;
  label?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  id?: string;
  disabled?: boolean;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};
