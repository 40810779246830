import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ListPage, ModelAuditLogList } from '/src/components';
import { config } from '/src/config';
import { getPageTitle } from '/src/utils';

export const ListModelAuditLogsPage = observer(() => {
  const isLoading = false;

  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Change Logs');
  }, []);

  return (
    <ListPage title="Change Logs" isLoading={isLoading}>
      <ModelAuditLogList showSearchInput showFilters />
    </ListPage>
  );
});
