import { Role } from '/src/lib/models';

/**
 * List Roles
 */
export type TListRolesRequest = {
  is_internal: boolean;
};

export type TListRolesResponse = Role[];
