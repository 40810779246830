import { ColumnConfig, DataTable } from 'grommet';
import { Link as LinkIcon } from 'grommet-icons';
import { useRef } from 'react';
import { Box, DataTableHeader, DataTableItem, Link } from '/src/components/common';
import { Client } from '/src/lib/models';
import { getFormattedDateString } from '/src/utils';

export const ClientDataTable: React.FC<ClientDataTableProps> = (props) => {
  const { clients, showFields, onMore } = props;

  /** Refs **/
  const defaultColumns = useRef<ColumnConfig<Client>[]>([
    {
      property: 'name',
      header: <DataTableHeader title="Client Name" toUpperCase />,
      size: '60%',
      primary: true,
      render: (client: Client) => (
        <Box pad={{ vertical: '1rem' }}>
          <Link to={`/clients/${client.id}`} fontFamily="Lato, sans-serif" textDecoration="none">
            <DataTableItem color="accent-1" weight={700}>
              {client.name}
            </DataTableItem>
          </Link>
        </Box>
      ),
    },
    {
      property: 'start_date',
      header: <DataTableHeader title="FuSE Start" toUpperCase />,
      render: (client: Client) => <DataTableItem>{getFormattedDateString(client.start_date)}</DataTableItem>,
    },
    {
      property: 'website',
      sortable: false,
      size: '15%',
      align: 'start',
      header: <DataTableHeader title="URL" />,
      render: (client: Client) => (
        <Box width="22px" height="22px">
          <Link to={client.website ?? ''} target="_blank" referrerPolicy="no-referrer">
            <LinkIcon color="dark-1" size="22px" />
          </Link>
        </Box>
      ),
    },
  ]);

  /** Computed **/
  const columns = showFields?.length
    ? defaultColumns.current.filter(({ property }) => showFields.includes(property))
    : defaultColumns.current;

  /** Render **/
  return (
    <DataTable
      id="client_list_data_table"
      pad={{ horizontal: '1.5rem' }}
      step={20}
      paginate
      columns={columns}
      data={clients}
      onMore={onMore}
      sortable
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      background={['light-6', 'white']}
      border={{ color: 'light-2', side: 'bottom', size: 'small' }}
    />
  );
};

export type ClientDataTableProps = {
  clients?: Client[];
  showFields?: string[];
  onMore?: () => void;
};
