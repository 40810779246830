import { Pagination } from 'grommet';
import React, { useEffect, useState } from 'react';
import { ClientDataTable } from './ClientDataTable';
import { ApiError, ClientService, TListClientsRequest } from '/src/api';
import {
  AddButton,
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  ClientIcon,
  Filters,
  LoadingSpinner,
  SearchInput,
  Text,
} from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Client } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { TResponseMetadata } from '/src/lib/types';

export const ClientList: React.FC<ClientListProps> = (props) => {
  /* Props */
  const {
    title,
    isLoading: propIsLoading,
    setIsLoading: propSetIsLoading,
    clients: propClients,
    fetchClients: propFetchClients,
    currentPage: propCurrentPage,
    setCurrentPage: propSetCurrentPage,
    metadata: propMetadata,
    showFilters,
    showSearchInput,
    showAddButton,
    hideHeader,
    ...boxProps
  } = props;

  /* Context */
  const userStore = useUserStore();
  const { handleApiError } = useGlobalStore();

  /* State */
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  // const [limit, setLimit] = useState(20);

  const [clients, setClients] = useState<Client[]>();
  const [metadata, setClientsMetadata] = useState<TResponseMetadata>();
  const [request, setRequest] = useState<TListClientsRequest>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filters, setFilters] = useState<Record<string, any>>({
    is_active: 1,
  });

  /** Memos **/
  const _currentPage = propCurrentPage ?? currentPage;
  const _isLoading = propIsLoading ?? isLoading;
  const _clients = propClients ?? clients;
  const _metadata = propMetadata ?? metadata;
  const hasClients = _clients;

  /** Methods **/
  const fetchClients = async (page?: number) => {
    if (!userStore.user) return;

    try {
      const { meta, data } = await ClientService.list({ ...request, page: page || request.page });

      setClients(data);
      setClientsMetadata(meta);
      propSetCurrentPage ? propSetCurrentPage(meta.current_page) : setCurrentPage(meta.current_page);
    } catch (err) {
      handleApiError(err as ApiError, toastMessages.listClients.error);
    } finally {
      propSetIsLoading ? propSetIsLoading(false) : setIsLoading(false);
      setIsFiltering(false);
      setIsSearching(false);
    }
  };

  const search = (query?: string) => {
    setRequest({
      ...request,
      page: 1,
      name: query,
    });
    setIsSearching(true);
  };

  const applyFilters = () => {
    const req = { ...request };
    req.is_active = filters.is_active === 1 ? true : filters.is_active === 0 ? false : undefined;
    setRequest(req);
    setIsFiltering(true);
  };

  const clearFilters = () => {
    setRequest({ page: 1 });
    setFilters({ is_active: 2 });
    setIsFiltering(true);
  };

  /* Effects */
  useEffect(() => {
    if (isSearching || isFiltering) {
      propFetchClients ? propFetchClients() : fetchClients();
    }
  }, [isSearching, isFiltering]);

  useEffect(() => {
    if (!_clients) {
      if (Object.keys(filters).length) applyFilters();
      else propFetchClients ? propFetchClients(_currentPage) : fetchClients(_currentPage);
    }
  }, [_clients, _currentPage, filters]);

  useEffect(() => {
    if (!_isLoading && _metadata && _currentPage !== _metadata.current_page) {
      propSetIsLoading ? propSetIsLoading(true) : setIsLoading(true);
      propFetchClients ? propFetchClients(_currentPage) : fetchClients(_currentPage);
    }
  }, [_isLoading, _metadata, _currentPage]);

  /* Render */
  return (
    <Box direction="row" gap="medium" fill="horizontal">
      <Card {...boxProps}>
        {!hideHeader && (
          <CardHeader title={title || 'Client List'} icon={<ClientIcon />}>
            {showSearchInput && searchQuery !== undefined && setSearchQuery !== undefined && (
              <SearchInput
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onSearch={search}
                isSearching={isSearching}
              />
            )}
            {showAddButton && userStore.isFuseAdmin && <AddButton label="Add Client" targetUrl={'/clients/create'} />}
          </CardHeader>
        )}
        <CardBody pad="none" gap="none">
          <Box elevation="small">
            {(_isLoading || isSearching || isFiltering) && <LoadingSpinner />}
            {!_isLoading && !isSearching && !isFiltering && <ClientDataTable clients={_clients} />}
            {!hasClients?.length && !_isLoading && !isSearching && (
              <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
                <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                  No clients found.
                </Text>
              </Box>
            )}
          </Box>
          {!!hasClients?.length && !!_metadata && !!_currentPage && (!!propSetCurrentPage || !!setCurrentPage) && (
            <Box pad="1.5rem" flex="grow">
              <Pagination
                alignSelf="center"
                size="small"
                page={_currentPage}
                step={_metadata?.per_page}
                numberItems={_metadata?.total}
                onChange={(e) => (propSetCurrentPage ? propSetCurrentPage(e.page) : setCurrentPage(e.page))}
              />
            </Box>
          )}
        </CardBody>
      </Card>
      {showFilters && (
        <Filters
          isFiltering={isFiltering}
          onSubmit={() => applyFilters()}
          onClear={() => clearFilters()}
          filters={[
            {
              label: 'Client Status',
              value: filters.is_active,
              setValue: (value) => setFilters({ ...filters, is_active: value }),
              options: [
                { label: 'All', value: 2 },
                { label: 'Active', value: 1 },
                { label: 'Inactive', value: 0 },
              ],
            },
          ]}
        />
      )}
    </Box>
  );
};

export type ClientListProps = BoxProps & {
  title?: string;
  clients?: Client[];
  fetchClients?: (page?: number) => Promise<void>;
  metadata?: TResponseMetadata;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  showAddButton?: boolean;
  showFilters?: boolean;
  showSearchInput?: boolean;
  hideHeader?: boolean;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
};
