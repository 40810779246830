import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Text } from '/src/components/common';

export type FormCardSectionProps = PropsWithChildren &
  BoxProps & {
    title?: string;
    subtitle?: string | React.ReactNode;
    row?: boolean;
    button?: React.ReactNode;
  };

export const FormCardSection: React.FC<FormCardSectionProps> = (props) => {
  const { children, title, subtitle, row, button, ...boxProps } = props;

  return (
    <Box gap="1rem" {...boxProps}>
      {(title || button) && (
        <Box row align="center" gap="xsmall">
          {!!title && (
            <Text color="dark-1" size="large" weight={300}>
              {title}
            </Text>
          )}
          {button}
        </Box>
      )}
      {subtitle &&
        (typeof subtitle === 'string' ? (
          <Text size="medium" fontFamily="Lato, sans-serif">
            {subtitle}
          </Text>
        ) : (
          subtitle
        ))}
      {row ? (
        <Box direction="row" gap="1rem">
          {children}
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};
