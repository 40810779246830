import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { config } from '../config';
import { useSearchStore } from '../context';
import { Client, Equipment, Facility, User } from '../lib/models';
import { ModelType } from '../lib/types';
import { getPageTitle } from '../utils';
import {
  Box,
  Card,
  CardBody,
  ClientList,
  EquipmentList,
  FacilityList,
  ListPage,
  PageHeader,
  SearchEntitySelect,
  SearchFieldSelect,
  SearchQueryInput,
  SearchResultsBar,
  Text,
  UserList,
} from '/src/components';

const SearchPage = observer(() => {
  /** Context **/
  const searchStore = useSearchStore();

  /** Computed **/
  const isLoading = false;

  /** Effects **/
  useEffect(() => {
    document.title = getPageTitle(config.client.basePageTitle, 'Search');
  }, []);

  /** Render **/
  return (
    <ListPage title="Search Results" pageHeader={<PageHeader title="Search" />} isLoading={isLoading}>
      <Box fill="horizontal">
        <Card>
          <CardBody pad={{ horizontal: 'none', top: 'medium' }} gap="medium">
            <Box direction="row" pad={{ horizontal: 'medium' }} gap="small">
              <SearchEntitySelect labelSize="1rem" labelWeight={400} fill="horizontal" />
              <SearchFieldSelect labelSize="1rem" labelWeight={400} fill="horizontal" />
            </Box>
            {!!searchStore.searchEntity && !!searchStore.searchField && (
              <Box animation={{ type: 'slideDown', duration: 300 }} pad={{ horizontal: 'medium' }}>
                <SearchQueryInput fill="horizontal" />
              </Box>
            )}
            <SearchResultsBar pad={{ horizontal: 'medium' }} />
            {!!searchStore.results && !searchStore.resultsMetadata?.total && (
              <Box
                pad={{ vertical: 'medium' }}
                background="light-6"
                justify="center"
                border={{ side: 'top', color: 'brand', size: '3px' }}
              >
                <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                  No searchStore.results found.
                </Text>
              </Box>
            )}
            {!!searchStore.results && !!searchStore.resultsMetadata?.total && (
              <>
                {searchStore.searchEntity === ModelType.Clients && (
                  <ClientList
                    clients={searchStore.results as Client[]}
                    fetchClients={searchStore.search}
                    metadata={searchStore.resultsMetadata}
                    currentPage={searchStore.currentPage}
                    setCurrentPage={searchStore.setCurrentPage}
                    isLoading={searchStore.isSearching}
                    hideHeader
                    border={{ side: 'top', color: 'brand', size: '3px' }}
                    round="0"
                    elevation="none"
                  />
                )}
                {searchStore.searchEntity === ModelType.Facilities && (
                  <FacilityList
                    clientId={NaN}
                    facilities={searchStore.results as Facility[]}
                    fetchFacilities={searchStore.search}
                    metadata={searchStore.resultsMetadata}
                    currentPage={searchStore.currentPage}
                    setCurrentPage={searchStore.setCurrentPage}
                    isLoading={searchStore.isSearching}
                    hideHeader
                    boxProps={{
                      border: { side: 'top', color: 'brand', size: '3px' },
                      borderRadius: '0',
                      elevation: 'none',
                    }}
                  />
                )}
                {searchStore.searchEntity === ModelType.Equipment && (
                  <EquipmentList
                    clientId={NaN}
                    facilityId={NaN}
                    equipment={searchStore.results as Equipment[]}
                    fetchEquipment={searchStore.search}
                    metadata={searchStore.resultsMetadata}
                    currentPage={searchStore.currentPage}
                    setCurrentPage={searchStore.setCurrentPage}
                    isLoading={searchStore.isSearching}
                    hideHeader
                    border={{ side: 'top', color: 'brand', size: '3px' }}
                    round="0"
                    elevation="none"
                  />
                )}
                {searchStore.searchEntity === ModelType.Users && (
                  <UserList
                    users={searchStore.results as User[]}
                    fetchUsers={searchStore.search}
                    metadata={searchStore.resultsMetadata}
                    currentPage={searchStore.currentPage}
                    setCurrentPage={searchStore.setCurrentPage}
                    isLoading={searchStore.isSearching}
                    hideHeader
                    boxProps={{
                      border: { side: 'top', color: 'brand', size: '3px' },
                      borderRadius: '0',
                      elevation: 'none',
                    }}
                  />
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Box>
    </ListPage>
  );
});

export default SearchPage;
